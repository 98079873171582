import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Form, Select, Row, Spin } from 'antd';

import moment from 'moment';
import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

const Option = Select.Option;

class ReportsHomeByActivity extends Component {
  state = {
    ministries: [],
    activities: [],
    selectedMinistry: '',
    selectedActivity: '',
    loadingMinistries: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.setState({ loadingItems: true });
    this.getMinistries()
      .then(res => this.setState({ ministries: res.data, loadingMinistries: false }))
      .catch(err => console.log(err));
  }

  getMinistries = async () => {
    const response = await amsAPI.getUrl(`/ams/ministries`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getActivities = async (ministry_id) => {
    const response = await amsAPI.getUrl(`/ams/activities?ministry_id=${ministry_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleMinistryChange = async (ministry_id) => {
    this.setState({ selectedMinistry: ministry_id })
    this.getActivities(ministry_id)
      .then(res => this.setState({ activities: res.data }))
      .catch(err => console.log(err));
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const {
      ministries, selectedMinistry, loadingMinistries,
      activities, selectedActivity,
    } = this.state;

    if (loadingMinistries) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Ministry Report Page - By Activity</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h4>Please select the activity you would like to generate a report.</h4>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Ministry:">
                  <Select
                    showSearch
                    placeholder="Select the ministry"
                    dropdownMatchSelectWidth={false}
                    onChange={this.handleMinistryChange}
                    value={selectedMinistry}
                  >
                    {ministries.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Activity:">
                  <Select
                    showSearch
                    placeholder="Select the activity"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.setState({ selectedActivity: value })}
                    value={selectedActivity}
                    disabled={!selectedMinistry}
                  >
                    {activities.map(item =>
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {constants.activities[item.name]}
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <NavLink to={`/reports/attendance_by_activity?activity_id=${selectedActivity}`}>
                <Button
                  block
                  type="primary"
                  disabled={ !selectedActivity }
                >
                  Next
                </Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ReportsHomeByActivity;