import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin } from 'antd';
import { stringify } from 'query-string';
import { Component } from 'react';

import _ from "lodash";
import moment from "moment";

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './List.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};


let timeout;
let currentValue;

function modalError() {
  Modal.error({
    title: 'Attendance Submission Error',
    content: 'Sorry, but your attendance has already been submitted.',
  });
}

class AddActivityAttendee extends Component {
  state = {
    member: '',
    channel: '',
    activity: {},
    activities: [],
    members: [],
    attendanceDateTime: null,
    isLoading: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    let query = { limit: 100 };
    if (this.props.scope) query.scope = this.props.scope;

    this.setState({ isLoading: true });
    this.callApi(`/ams/activities?${stringify(query)}`)
      .then(res => this.setState({ activities: res.data, isLoading: false }))
      .catch(err => console.log(err));

    const { activityId } = this.props.match.params;
    this.getActivity(activityId)
      .then(res => this.setState({ activity: res.data }))
      .catch(err => console.log(err));

  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { activityId } = this.props.match.params;
    amsAPI.fetchUrl(`/ams/activity_attendance`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        activityId: activityId,
        memberIds: [this.state.member],
        userMemberId: this.props.userMemberId,
        submissionDateTime: this.state.attendanceDateTime,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Attendance successfully submitted.');
          this.setState({ submitting: false });
          this.props.history.push(`/activities/${activityId}/attendees`);
        } else if (response.error_id === 'ERR001') {
          this.setState({ submitting: false });
          message.error('Wrong pass key.');
        } else if (response.error_id === 'ERR002') {
          this.setState({ submitting: false });
          message.error('All gathering links have been allocated. Please contact your assigned worker.');
        } else if (!_.isEmpty(response.error_id)) {
          this.setState({ submitting: false });
          message.error(response.error_message);
          this.getRequiredInfoFromAPI();
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false, mode: '' });
        modalError();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(`Error submitting attendance. ${ err ? err.message : ''}`);
    });

  };

  handleActivityChange = (id) => {
    this.getActivity(id)
      .then(async res => {
        const { activity } = res;

        this.setState({
          activity,
        });
      })
      .catch(err => console.log(err));
  }

  getActivity = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/activities/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSearch = value => {
    if (value) {
      this.fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = member => {
    this.setState({ member });
  };

  fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    let ministry_id = this.state.activity.ministry._id;
    async function fake() {
      const str = {
        member_name: value,
        ministry_id: ministry_id,
      };
      amsAPI.getUrl(`/ams/members?${stringify(str)}`)
        .then(async response => {
          const body = await response.json();
          if (response.status !== 200) throw Error(body.message);
          return body;
        })
        .then(d => {
          if (currentValue === value) {
            callback(d.members);
          }
        });
    }

    timeout = setTimeout(fake, 300);
  }

  render() {
    const { activityId } = this.props.match.params;
    const {
      attendanceDateTime, isLoading,
      activities, members, member,
    } = this.state;
    const disableSubmit = !attendanceDateTime || _.isEmpty(member);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Activity Attendee Form</h2>
            </Col>
          </Row>
          {isLoading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
                <Form {...formItemLayout}>
                  <Form.Item
                    label="Activity"
                  >
                    <Select
                      placeholder="Select an activity"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 350 }}
                      onChange={this.handleActivityChange}
                      defaultValue={activityId}
                      disabled={true}
                    >
                      {activities.map(item =>
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {constants.activities[item.name]}
                      </Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    placeholder={"Input member name or id"}
                    label="Member"
                    validateStatus={_.isEmpty(member) ? "error" : ""}
                    help={_.isEmpty(member) ? "Please input member" : ""}
                  >
                    <Select
                      showSearch
                      placeholder={"Input member name or id"}
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearch}
                      onChange={this.handleChange}
                      notFoundContent={null}
                      value={member}
                      allowClear={true}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Attendance Date & Time"
                    validateStatus={ !attendanceDateTime ? "error" : ""}
                    help={ !attendanceDateTime ? "Please set attendance date and time" : ""}
                  >
                    <DatePicker
                      showToday
                      format="YYYY-MM-DD h:mm a"
                      showTime={{ use12Hours: true, format: "h:mm a" }}
                      placeholder="Date and time"
                      value={this.state.attendanceDateTime}
                      onChange={(value) => this.setState({ attendanceDateTime: value })}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      block
                      onClick={this.handleSubmit}
                      type="primary"
                      htmlType="submit"
                      disabled={disableSubmit}
                    >Register</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            </>
          }
        </div>
      </div>
    );
  }
}

export default AddActivityAttendee;
