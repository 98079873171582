import { Badge, Card, Col, Icon, PageHeader, Row } from 'antd';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class ReportsHome extends Component {

  render() {
    return (
      <PageHeader
        title={"Which report would you like to generate?"}
      >
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/by_gathering">
                    <Icon type="calendar" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/by_date_range">
                    <Icon type="schedule" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/individual">
                    <Badge count={"v1"}>
                      <Icon type="user" style={{ fontSize: '4em', color: '#08c' }}/>
                    </Badge>
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/v2/individual">
                    <Badge count={"v2"}>
                      <Icon type="user" style={{ fontSize: '4em', color: '#08c' }}/>
                    </Badge>
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/individual_attendance_reports">
                    <Badge count={"Report"}>
                      <Icon type="ordered-list" style={{ fontSize: '4em', color: '#08c' }}/>
                    </Badge>
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/by_activity">
                    <Icon type="crown" style={{ fontSize: '4em', color: '#08c' }}/>
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/by_group">
                    <Icon type="team" style={{ fontSize: '4em', color: '#08c' }}/>
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/reports/sabbath_celebrants">
                    <Icon type="gift" style={{ fontSize: '4em', color: '#08c' }}/>
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default ReportsHome;
