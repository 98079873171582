import React, { Component } from 'react';
import { Col, Row, Table, Spin, Button } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';
import * as jsPDF from 'jspdf';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Report.css';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: key => <span>{key}</span>
  },
  {
    title: 'Gathering',
    dataIndex: 'gatheringId',
    key: 'gatheringId',
    render: gatheringId => <span>{constants.gatherings[gatheringId.name]} ({gatheringId.type})</span>,
  },
  {
    title: 'Date',
    dataIndex: 'startDateTime',
    key: 'startDateTime',
    render: startDateTime => <span>{`${moment(startDateTime).format("MMM.DD(ddd), h:mmA")}`}</span>,
  },
  {
    title: 'Member',
    dataIndex: 'memberId',
    key: 'memberId',
    render: memberId => <span>{memberId ? memberId.name : ""}</span>,
  },
];

class AttendanceReport extends Component {
  state = {
    attendance: [],
    period: "",
    loadingAttendance: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
      this.getAttendance()
        .then(res => {
          let modResult = res.result.sort((a, b) => { 
            return a.memberId.localeChurchId.name - b.memberId.localeChurchId.name;
          });
          this.setState({ attendance: modResult, loadingAttendance: false })
        })
        .catch(err => console.log(err));
  }

  getAttendance = async () => {
    this.setState({ loadingAttendance: true })

    const query = qs.parse(this.props.location.search);
    const { localeChurchId, gatheringType, period } = query;
    const response = await amsAPI.getUrl(`/ams/locale_attendance?localeChurchId=${localeChurchId}&gatheringType=${gatheringType}&period=${period}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  downloadTxtFile = () => {
    const { attendance } = this.state;
    let modResult = attendance.sort((a, b) => { 
      return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
    });
    console.log('modResult', modResult);

    const query = qs.parse(this.props.location.search);
    const { period, gatheringType } = query;

    let outputTxt = "";
    outputTxt = outputTxt + `Attendance Report ${period.replace(",", " to ")}`;
    outputTxt = outputTxt + "\r\n";
    outputTxt = outputTxt + constants.gatherings[gatheringType];
    outputTxt = outputTxt + "\r\n";

    let localeChurch = "";
    let counter = 1;
    modResult.forEach(item => {
      if (localeChurch !== item.memberId.localeChurchId.name) {
        counter = 1;
        outputTxt = outputTxt + "\r\n";
        localeChurch = item.memberId.localeChurchId.name;
        outputTxt = outputTxt + item.memberId.localeChurchId.name.toUpperCase() + "\r\n";
      } 
      const member = `  ${counter}. ${item.memberId.name}`;
      outputTxt = outputTxt + member + "\r\n";
      counter++;
    });

    const element = document.createElement("a");
    const file = new Blob([outputTxt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Attendance Report_${period.replace(",", " to ")}.txt`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  jsPdfGenerator = () => {
    const { attendance } = this.state;
    var doc = new jsPDF('p', 'pt');

    const query = qs.parse(this.props.location.search);
    const { period, gatheringType } = query;

    let y = 20;
    const title = `Attendance Report ${period.replace(",", " to ")}`;
    doc.text(20, y, title);
    const gathering = constants.gatherings[gatheringType];
    y = y + 20;
    doc.text(20, y, gathering);
    y = y + 20;

    const MARGIN = 15;
    let localeChurch = "";
    let counter = 1;
    attendance.forEach(item => {
      if (localeChurch !== item.memberId.localeChurchId.name) {
        counter = 1;
        y = y + 20;
        localeChurch = item.memberId.localeChurchId.name;
        doc.text(20, y, `Locale of ${item.memberId.localeChurchId.name}`);
        y = y + 20;
      } 
      doc.text(20, y, `  ${counter}. ${item.memberId.name}`);
      y = y + MARGIN;
      counter++;
    });
    
    // Save the Data
    const fileName = `Attendance Report_${period.replace(",", " to ")}.pdf`;
    doc.save(fileName);
  }

  render() {
    const { attendance, loadingAttendance } = this.state;
    const loading = loadingAttendance;
    const query = qs.parse(this.props.location.search);
    const { period } = query;
    const [ startDate, endDate ] = period.split(",");

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      );  
    }

    let modResult = [];
    if (attendance.length > 0) {
      let i = 1;
      attendance.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button
                type="primary"
                onClick={this.jsPdfGenerator}
                style={{display: 'none'}}
              > Generate PDF</Button>
              <Button
                type="primary"
                onClick={this.downloadTxtFile}
                style={{marginLeft: '10px'}}
              > Generate Text</Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
            {(modResult && modResult.length === 0) ?
              <h2>{`No attendance available for ${period}.`}</h2>
            :
              <div>
                <h3>{`Here's the attendance from ${startDate} to ${endDate}:`}</h3>
                <Table pagination={false} columns={columns} dataSource={modResult} />
              </div>
            }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AttendanceReport;
