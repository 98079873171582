import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button, Col, Form, Select, Row, DatePicker,
} from 'antd';
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ReportsHomeByDateRange extends Component {
  state = {
    selectedGatheringName: '',
    type: '',
    startDate: null,
    endDate: null,
    nationality: '',
    gatheringTypes: [],
    ministries: [],
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
  }

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  render() {
    const nationalities = constants.nationalities;
    const {
      gatheringTypes, type, ministries, nationality,
      selectedGatheringName, selectedMinistry,
      startDate, endDate,
    } = this.state;
    const liveOrReplay = Object.entries(constants.gatheringTypes);
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })

    let query = {
      gathering_name: selectedGatheringName,
      type: type,
      startDate: startDate,
      endDate: endDate,
      nationality: nationality,
      ministryId: selectedMinistry,
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Gathering Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h4>Please select the gathering you would like to generate a report.</h4>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item
                  label="Gathering"
                  validateStatus={ !selectedGatheringName ? "error" : ""}
                  help={ !selectedGatheringName ? "Please select a gathering" : ""}
                >
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={(value) => this.setState({ selectedGatheringName: value })}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Type"
                >
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ type: value })}
                  >
                    {liveOrReplay.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Start Date"
                  validateStatus={ !startDate ? "error" : ""}
                  help={ !startDate ? "Please input start date" : ""}
                >
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD"
                    placeholder="Start Date"
                    onChange={(date, dateString) => this.setState({ startDate: dateString })}
                  />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  validateStatus={ !endDate ? "error" : ""}
                  help={ !endDate ? "Please input end date" : ""}
                >
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD"
                    placeholder="End Date"
                    onChange={(date, dateString) => this.setState({ endDate: dateString })}
                  />
                </Form.Item>
                <Form.Item label="Nationality">
                  <Select
                    showSearch
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => this.setState({ nationality: value })}
                    allowClear={true}
                    defaultValue={this.state.nationality}
                  >
                    {nationalities.map(item =>
                      <Option key={item} value={item}>{item}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Ministry">
                  <Select
                    placeholder="Select a ministry"
                    dropdownMatchSelectWidth={false}
                    allowClear={true}
                    onChange={value => this.setState({ selectedMinistry: value })}
                    value={selectedMinistry}
                  >
                    {ministries.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <NavLink to={`/reports/locale_attendance_by_date_range?${qs.stringify(query)}`}>
                <Button
                  block
                  type="primary"
                  disabled={!selectedGatheringName}
                >
                  Next
                </Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ReportsHomeByDateRange;
