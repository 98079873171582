import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {
  Button, Col, Icon, Row, Spin, Table, Popconfirm, message
} from 'antd';

import amsAPI from '../../../apis/amsAPI';

import 'antd/dist/antd.css';
import './SystemList.css';

class Roles extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      churchId: '',
      roles: [],
      loading: false,
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'No',
        dataIndex: 'rowKey',
        key: 'rowKey',
        render: rowKey => <span>{rowKey.rowNum}</span>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: name => <span>{name}</span>,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        render: code => <span>{code}</span>,
        sorter: (a, b) => a.code.localeCompare(b.code),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink
              style={{ padding: 10 }}
              to={`/system/roles/${record._id}/edit`}
            >
              <Icon type={"edit"}/>
            </NavLink>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.getRoles()
      .then(res => this.setState({ roles: res.data, loading: false }))
      .catch(err => console.log(err));
  }

  getRoles = async () => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(`/ams/roles`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (id) => {
    await amsAPI.fetchUrl(`/ams/roles/${id}`, {
      method: 'DELETE',
    });
    message.success("Role successfully removed.")
    this.props.history.push('/system/roles');
  };

  render() {
    this.createTableColumns();
    const { roles, loading } = this.state;
    let modResult = [];
    let i = 0;
    roles.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(roles.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no registered roles.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the list of roles:`}</h3>
                  <Table columns={this.columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Roles);
