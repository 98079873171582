import { Button, Col, Icon, Layout, Menu, message, Row } from 'antd';
import _ from 'lodash';
import PropTypes from "prop-types";
import { Component } from 'react';
import { withRouter } from "react-router";
import { Route } from 'react-router-dom';

import ReactGA from 'react-ga';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import UrlPattern from 'url-pattern';

import {
  Activities, ActivityAttendees, EditActivity, NewActivity, AddActivityAttendee,
} from './activity';
import {
  Candidates, EditCandidate, NewCandidate
} from './ams/candidates';
import {
  CreateChurchGroup, CreateForm, CreateLocaleChurch, CreateMenu, CreateMenuItem, CreateMinistry, EditLocaleChurch, EditMinistry, EditSystemUser, Login, NewSystemUser
} from './form';
import { AddGatheringAttendee, AttendanceApproval, AttendanceSearch, CreateGathering, CreateGatheringLink, EditGathering, EditGatheringWeeknum, EditMemberAttendance, GatheringAttendees, GatheringHistory, GatheringLinks, Gatherings, GatheringZoomLinks, LinkAccesses, SharedGatheringLink } from './gathering';
import { Links, ListFeed, LocaleChurches, LocaleChurchMembers, MemberAttendanceSearch, MenuItems, Menus, Ministries, Stores } from './list';
import { MemberForm, MembersList, NewMember, RegisterMember } from './member';

import { ChurchDistricts, EditChurchDistrict, NewChurchDistrict } from './divisions/church_district';
import { ChurchDivisions, EditChurchDivision, NewChurchDivision } from './divisions/church_division';
import { Countries, EditCountry, NewCountry } from './divisions/country';
import { Unauthorized } from './errors';
import {
  BankAccountHistory, BankAccounts, EditBankAccount, NewBankAccount
} from './finance/bank_account';
import { EditRemittance, NewRemittance, RemittanceHistory, Remittances, ShowRemittance } from './finance/remittance';
import { RemittanceForActionReport, RemittanceReceiptsReport } from './finance/reports';
import { EditRole, NewRole } from './form/system';
import { MemberHistory } from './history';
import {
  AdminHome, DivisionHome, FinanceHome, KAPIHome, LocaleChurchHome, MinistryHome, RemittanceReportsHome, RemittancesHome, ReportsHome, ReportsHomeByActivity, ReportsHomeByDateRange, ReportsHomeByGathering, ReportsHomeGroupWeeklyReport, ReportsHomeSabbathCelebrants, Secret,
  SystemHome, UploadHome, ZoomHome
} from './homes';
import { EditKAPIAnnouncement, KAPIAnnouncements, NewKAPIAnnouncement } from './kapi/announcement';
import { EditKAPIRemittance, KAPIRemittances, NewKAPIRemittance } from './kapi/remittance';
import { Roles, SystemUsers } from './list/system';
import {
  AttendanceReport, AttendanceReportByActivity, AttendanceReportByDateRange, AttendanceReportByGathering, AttendanceReportByGatheringElders, AttendanceReportByGroup, IndividualAttendanceReport, IndividualAttendanceReports, IndividualAttendanceReportV2, SabbathCelebrantsByMonth
} from './reports';
import { GenerateToken } from './security';
import { UploadCountries, UploadLocaleChurches, UploadMemberLineLinks, UploadMembers } from './upload';
import { ZoomLogs, ZoomMinistryLogs, ZoomAuditLogs } from './zoom';

import amsAPI from '../apis/amsAPI';
import * as constants from '../helpers/constants';

import 'antd/dist/antd.css';
import './ContentWrapper.css';

ReactGA.initialize('UA-146791855-1');

const {
  Header, Content,
} = Layout;

class AdminWrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props){
    super(props);
    this.state = {
      isLocaleMembersPage: false,
      isSignedIn: false,
      isGoogleLogin: false,
      services: {
        ams: {
          isAvailable: _.isEmpty(process.env.REACT_APP_AMS_API) ? false : true,
          url: process.env.REACT_APP_AMS_API,
        },
        fin: {
          isAvailable: _.isEmpty(process.env.REACT_APP_FINANCE_API) ? false : true,
          url: process.env.REACT_APP_FINANCE_API,
        },
        sys: {
          isAvailable: _.isEmpty(process.env.REACT_APP_SYSTEM_API) ? false : true,
          url: process.env.REACT_APP_SYSTEM_API,
        },
        tic: {
          isAvailable: _.isEmpty(process.env.REACT_APP_TICKETS_API) ? false : true,
          url: process.env.REACT_APP_TICKETS_API,
        },
        kapi: {
          isAvailable: _.isEmpty(process.env.REACT_APP_KAPI_API) ? false : true,
          url: process.env.REACT_APP_KAPI_API,
        },
      },
      membersFilter: { },
    };
    this.goBack = this.goBack.bind(this);
    this.goToNew = this.goToNew.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
    this.setLogin = this.setLogin.bind(this);
    this.clearMembersFilter = this.clearMembersFilter.bind(this);
  }

  goBack() {
    const pathname = this.props.location.pathname;
    const isListPage = pathname.substring(1).match(
        /^(stores|locale_churches|gatherings|church_groups|ministries|members|finance|divisions)$/,
      );
    const isFinancePage = pathname.substring(1).match(
      /^(bank_accounts|remittances|remittances_closed)$/,
    );
    const isDivisionPage = pathname.substring(1).match(
      /^(church_divisions|church_districts|countries)$/,
    );

    const pattern = new UrlPattern('/gatherings/:gatheringId/attendees');
    const isAttendeesPage = pattern.match(pathname);
    const approvalPattern = new UrlPattern('/gatherings/:gatheringId/approvals');
    const isApprovalsPage = approvalPattern.match(pathname);
    const linksPattern = new UrlPattern('/gatherings/:gatheringId/links');
    const isLinksPage = linksPattern.match(pathname);
    const activityAttendeesPattern = new UrlPattern('/activities/:activityId/attendees');
    const isActivityAttendeesPage = activityAttendeesPattern.match(pathname);

    if (isListPage) {
      this.props.history.push("/");
    } else if (isFinancePage) {
      this.props.history.push("/finance");
    } else if (isDivisionPage) {
      this.props.history.push("/divisions");
    } else if (isAttendeesPage || isApprovalsPage) {
      this.props.history.push("/gatherings");
    } else if (isLinksPage) {
      this.props.history.push("/gatherings");
    } else if (isActivityAttendeesPage) {
      this.props.history.push("/activities");
    } else {
      this.props.history.goBack();
    }
  }

  goToNew() {
    const pathname = this.props.location.pathname;
    const newUrl = pathname + '/new';
    this.props.history.push(newUrl);
  }

  goHome() {
    this.props.history.push("/");
  }

  onLoginSuccess = info => {
    amsAPI.fetchUrl(`/ams/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        email: info.profileObj.email,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        message.success("Successfully logged in.");
        const body = await res.json();
        this.setState({
          isSignedIn: true,
          isGoogleLogin: true,
          userInfo: {
            localeChurchId: body.localeChurchId,
            memberId: body._id,
            roles: body.roles,
            name: body.name,
            isAdmin: body.isAdmin,
            userMemberId: body._id,
          },
          localeChurchId: body.localeChurchId,
          userMemberId: body.id,
          err: null
        })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error logging in.');
    });
  }

  setLogin = (info) => {
    this.setState({
      ...info,
      isSignedIn: true,
      err: null
    });
  }

  logoutUser = () => {
    this.setState({
      isSignedIn: false,
      isGoogleLogin: false,
    });
  }

  onLoginFailed = (err) => {
    console.log('login failed', err)
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  onLogoutSuccess = () => {
    this.setState({
      isSignedIn: false,
    })
  }

  onLogoutFailed = (err) => {
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  setMembersFilter = async (updatedFilter) => {
    const currentFilter = this.state.membersFilter;
    this.setState({
      membersFilter: { ...currentFilter, ...updatedFilter }
    });
  };

  clearMembersFilter = () => {
    this.setState({ membersFilter: {  } });
  };

  render() {
    const pathname = this.props.location.pathname;
    const { isSignedIn, isGoogleLogin } = this.state;
    const isAdd = pathname.indexOf('new') >= 0;
    const isListPage = pathname.substring(1).match(
        /^(roles|locale_churches|gatherings|church_groups|ministries|members|links|bank_accounts|remittances|church_divisions|church_districts|countries|activities|kapi\/remittances)$/,
      );
    const pattern = new UrlPattern('/locale_churches/:localeChurchId/members');
    const isLocaleMembersPage = pattern.match(pathname);
    const linksPattern = new UrlPattern('/gatherings/:gatheringId/links');
    const isLinksPage = linksPattern.match(pathname);
    const systemRolesPattern = new UrlPattern('/system/roles');
    const isSystemRolesPage = systemRolesPattern.match(pathname);
    const attendeesPattern = new UrlPattern('/gatherings/:gatheringId/attendees');
    const isAttendeesPage = attendeesPattern.match(pathname);
    const activityAttendeesPattern = new UrlPattern('/activities/:activityId/attendees');
    const isActivityAttendeesPage = activityAttendeesPattern.match(pathname);

    const displayAddButton = isListPage || isLocaleMembersPage || isLinksPage || isSystemRolesPage || isAttendeesPage || isActivityAttendeesPage;

    const remittanceStatuses = Object.keys(constants.remittanceStatuses);
    const auditRemittanceStatuses = ["duplicate", "received", "cancelled"];
    const openRemitanceStatuses = remittanceStatuses.filter(item => !auditRemittanceStatuses.includes(item));

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
              <Menu
                selectable={false}
                theme="dark"
                mode="horizontal"
                style={{ lineHeight: '20px' }}
              >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {(pathname !== "/") &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goHome}
                  >
                    <Icon type="home" />
                  </Button>
                }
                {(pathname !== "/" && isSignedIn) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goBack}
                    style={{ marginLeft: "5px" }}
                  >
                    Back
                  </Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="1"
              >
                {isSignedIn &&
                  <Button
                    type="default"
                    size="large"
                    disabled={isAdd}
                    onClick={this.goToNew}
                    style={
                      {
                        display: (displayAddButton) ? "inherit" : "none",
                        marginRight: '10px',
                      }
                    }
                  >Add</Button>
                }
                {(!isSignedIn && pathname !== "/") &&
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={this.onLoginSuccess}
                    onFailure={this.onLoginFailed}
                    cookiePolicy={'single_host_origin'}
                  />
                }
                {(isSignedIn && isGoogleLogin) &&
                  <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                    onFailure={this.onLogoutFailed}
                  />
                }
                {(isSignedIn && !isGoogleLogin) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.logoutUser}
                  >Logout</Button>
                }
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AdminHome
                          {...props}
                          services={this.state.services}
                          userInfo={this.state.userInfo}
                          clearMembersFilter={this.clearMembersFilter}
                        />
                      :
                        <Login
                          {...props}
                          setLogin={this.setLogin}
                          onLoginSuccess={this.onLoginSuccess}
                          onLoginFailed={this.onLoginFailed}
                        />
                    }
                  />

                  <Route exact path="/secret"
                    render={(props) =>
                      this.state.isSignedIn ? <Secret {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/members"
                    render={(props) =>
                      this.state.isSignedIn ?
                      <MembersList
                        {...props}
                        isSignedIn={this.state.isSignedIn}
                        userMemberId={this.state.userMemberId}
                        membersFilter={this.state.membersFilter}
                        setMembersFilter={this.setMembersFilter}
                      />
                      :
                      <Unauthorized />
                    }
                  />
                  <Route exact path="/members/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewMember
                          {...props}
                          services={this.state.services}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/members/:memberId/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <MemberForm
                          {...props}
                          services={this.state.services}
                          isSignedIn={this.state.isSignedIn}
                          userMemberId={this.state.userMemberId}
                          membersFilter={this.state.membersFilter}
                          setMembersFilter={this.setMembersFilter}
                        />
                      :
                      <Unauthorized />
                    }
                  />
                  <Route exact path="/members/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <MemberHistory
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ? <LocaleChurches {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateLocaleChurch {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/show"
                    render={(props) =>
                      this.state.isSignedIn ? <LocaleChurchHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditLocaleChurch {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/members"
                    render={(props) =>
                      this.state.isSignedIn ? <LocaleChurchMembers {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/members/new"
                    render={(props) =>
                      this.state.isSignedIn ? <RegisterMember {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/stores"
                    render={(props) =>
                      this.state.isSignedIn ? <Stores {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/stores/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateForm {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/stores/:storeId/menus"
                    render={(props) =>
                      this.state.isSignedIn ? <Menus {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/stores/:storeId/menus/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateMenu {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/stores/:storeId/menus/:menuId"
                    render={(props) =>
                      this.state.isSignedIn ? <MenuItems {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/stores/:storeId/menus/:menuId/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateMenuItem {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/gatherings"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Gatherings
                          {...props}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <CreateGathering
                          {...props}
                          userMemberId={this.state.userMemberId}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditGathering
                          {...props}
                          userMemberId={this.state.userMemberId}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/edit_weeknum"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditGatheringWeeknum
                          {...props}
                          userMemberId={this.state.userMemberId}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GatheringHistory
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/attendees"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GatheringAttendees
                          {...props}
                          scope={"locale"}
                          userMemberId={this.state.userMemberId}
                        /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/attendees/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AddGatheringAttendee
                          {...props}
                          scope={"locale"}
                          userMemberId={this.state.userMemberId}
                        /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/member_attendance/:attendanceId/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditMemberAttendance
                          {...props}
                          scope={"locale"}
                          userMemberId={this.state.userMemberId}
                        /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/gatherings/:gatheringId/approvals"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceApproval {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/search"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceSearch {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/member_attendance/search"
                    render={(props) =>
                      this.state.isSignedIn ? <MemberAttendanceSearch {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/links"
                    render={(props) =>
                      this.state.isSignedIn ? <GatheringLinks {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/link_accesses"
                    render={(props) =>
                      this.state.isSignedIn ? <LinkAccesses {...props} urlPath="gatherings" /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/links/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateGatheringLink {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/link"
                    render={(props) =>
                      this.state.isSignedIn ? <SharedGatheringLink {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/zoom_links"
                    render={(props) =>
                      this.state.isSignedIn ? <GatheringZoomLinks {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/links"
                    render={(props) =>
                      this.state.isSignedIn ? <Links {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/links/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateGatheringLink {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/church_groups"
                    render={(props) =>
                      this.state.isSignedIn ? <ListFeed {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_groups/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateChurchGroup {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/ministry_home"
                    render={(props) =>
                      this.state.isSignedIn ? <MinistryHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/ministries"
                    render={(props) =>
                      this.state.isSignedIn ? <Ministries {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/ministries/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateMinistry {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/ministries/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditMinistry {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/ministries/zoom_logs"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomMinistryLogs {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/activities"
                    render={(props) =>
                      this.state.isSignedIn ? <Activities {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/:_id/attendees"
                    render={(props) =>
                      this.state.isSignedIn ? <ActivityAttendees {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/:activityId/attendees/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AddActivityAttendee
                          {...props}
                          scope={"locale"}
                          userMemberId={this.state.userMemberId}
                        /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/:_id/link_accesses"
                    render={(props) =>
                      this.state.isSignedIn ? <LinkAccesses {...props}  urlPath="activites" /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/upload"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/upload/members"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadMembers {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/upload/line_links"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadMemberLineLinks {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/uploads/countries"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadCountries {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/uploads/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadLocaleChurches {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHomeByGathering {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/by_date_range"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHomeByDateRange {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/by_group"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ReportsHomeGroupWeeklyReport
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                      : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/locale_attendance"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReport {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/locale_attendance_by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByGathering {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_gathering_absentees"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByGatheringElders {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/individual"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <IndividualAttendanceReport
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/v2/individual"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <IndividualAttendanceReportV2
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/individual_attendance_reports"
                    render={(props) =>
                      this.state.isSignedIn ? <IndividualAttendanceReports {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/by_activity"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHomeByActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_date_range"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByDateRange {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/weekly_group_report"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByGroup
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/attendance_by_activity"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/sabbath_celebrants"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHomeSabbathCelebrants {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/sabbath_celebrants_by_month"
                    render={(props) =>
                      this.state.isSignedIn ? <SabbathCelebrantsByMonth {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/divisions"
                    render={(props) =>
                      this.state.isSignedIn ? <DivisionHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/church_divisions"
                    render={(props) =>
                      this.state.isSignedIn ? <ChurchDivisions {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_divisions/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewChurchDivision {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_divisions/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditChurchDivision {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/church_districts"
                    render={(props) =>
                      this.state.isSignedIn ? <ChurchDistricts {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_districts/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewChurchDistrict {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_districts/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditChurchDistrict {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/countries"
                    render={(props) =>
                      this.state.isSignedIn ? <Countries {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/countries/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewCountry {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/countries/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditCountry {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/zoom"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/zoom/logs"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomLogs {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/zoom/audit_logs"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomAuditLogs {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/kapi"
                    render={(props) =>
                      this.state.isSignedIn ? <KAPIHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/kapi/announcement"
                    render={(props) =>
                      this.state.isSignedIn ? <KAPIAnnouncements mode="kapi" {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/kapi/announcement/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewKAPIAnnouncement mode="kapi" {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/kapi/announcement/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditKAPIAnnouncement mode="kapi" {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/kapi/remittances"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <KAPIRemittances
                          mode="kapi"
                          userMemberId = {this.state.userMemberId}
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/kapi/remittances/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewKAPIRemittance mode="kapi" {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/kapi/remittances/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditKAPIRemittance
                          mode="kapi"
                          userMemberId = {this.state.userMemberId}
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/system"
                    render={(props) =>
                      this.state.isSignedIn ? <SystemHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/users"
                    render={(props) =>
                      this.state.isSignedIn ? <SystemUsers {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/users/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewSystemUser {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/users/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditSystemUser {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/system/roles"
                    render={(props) =>
                      this.state.isSignedIn ? <Roles {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/roles/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewRole {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/roles/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditRole {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/finance"
                    render={(props) =>
                      this.state.isSignedIn ? <FinanceHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittancesHome
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          pathname={"/remittances"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/international"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"international"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/mail"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          remitMethod={"mail"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/pickup"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          remitMethod={"worker-pickup"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          isLocaleChurchAccount={true}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittancesHome
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          pathname={"/remittances_closed"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/international"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"international"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          isLocaleChurchAccount={true}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/mail"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          remitMethod={"mail"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/pickup"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          remitMethod={"worker-pickup"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewRemittance
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditRemittance
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/show"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ShowRemittance
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/generate"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GenerateToken
                          {...props}
                          userMemberId={this.state.userMemberId}
                          model={"remittance"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceHistory
                          {...props}
                          userMemberId={this.state.userMemberId}
                          model={"remittance"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceReportsHome
                          {...props}
                          userMemberId = {this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports/receipts"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceReceiptsReport
                          {...props}
                          userMemberId = {this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports/for_action"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceForActionReport
                          {...props}
                          userMemberId = {this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/old_national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"old national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/old_kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"old kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/old_national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"old national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/old_kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userMemberId = {this.state.userMemberId}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"old kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/bank_accounts"
                    render={(props) =>
                      this.state.isSignedIn ? <BankAccounts {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/bank_accounts/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewBankAccount {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/bank_accounts/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                      <EditBankAccount
                        {...props}
                        userInfo={this.state.userInfo}
                      />
                      :
                      <Unauthorized />
                    }
                  />

                  <Route exact path="/bank_accounts/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <BankAccountHistory
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/candidates"
                    render={(props) =>
                      this.state.isSignedIn ?
                      <Candidates
                        {...props}
                        userInfo={this.state.userInfo}
                      />
                      :
                      <Unauthorized />
                    }
                  />
                  <Route exact path="/candidates/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewCandidate {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/candidates/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditCandidate
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                      :
                      <Unauthorized />
                    }
                  />

                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(AdminWrapper);
