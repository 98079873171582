import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button, Col, Row, Spin, Table, Form, Select, DatePicker,
} from 'antd';
import { stringify } from 'query-string';

import moment from 'moment';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './List.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ZoomMinistryLogs extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedActivity: '',
      activityDate: '',
      zoom_logs: [],
      loading: false,
      loadingTypes: false,
    };

    this.columns = (activities) => {
      return [
        {
          title: 'No',
          dataIndex: 'key',
          key: 'key',
          render: (text, record) => (
            <span>{record.key + 1}</span>
          ),
        },
        {
          title: 'User Name',
          dataIndex: 'user_name',
          key: 'user_name',
          width: 200,
          sorter: (a, b) => a.user_name.localeCompare(b.user_name),
          render: (text, record) => (
            <span>{record.user_name}</span>
          ),
        },
        {
          title: 'Activity',
          dataIndex: 'activity',
          key: 'activity',
          render: activity => (
            <span>{activity ? constants.activities[activity.name] : ""}</span>
          ),
        },
        {
          title: 'Date/Time',
          dataIndex: 'activity',
          key: 'date_time',
          width: 150,
          render: activity => (
            <span>{
              activity ? `${moment(activity.startDateTime).format("MMM.DD, YYYY (ddd) h:mmA")}` : ""
            }</span>
          ),
        },
        {
          title: 'Topic',
          dataIndex: 'topic',
          key: 'topic',
          render: topic => <span>{topic}</span>
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          render: email => <span>{email}</span>
        },
        {
          title: 'Join Time',
          dataIndex: 'join_time',
          key: 'join_time',
          sortDirections: ['descend'],
          sorter: (a, b) => moment(b.join_time).unix() - moment(a.join_time).unix(),
          render: join_time => <span>
            {join_time ? moment.utc(join_time).local().format("MMM.DD h:mmA") : ""}
          </span>
        },
        {
          title: 'Leave Time',
          dataIndex: 'leave_time',
          key: 'leave_time',
          sortDirections: ['descend'],
          sorter: (a, b) => moment(b.leave_time).unix() - moment(a.leave_time).unix(),
          render: leave_time => <span>
            {leave_time ? moment.utc(leave_time).local().format("MMM.DD h:mmA") : ""}
          </span>
        },
      ];
    }
  }

  componentDidMount() {
    let query = { };
    if (this.props.scope) query.scope = this.props.scope;

    this.setState({ loading: true });
    this.callApi(`/ams/zoom_ministry?${stringify(query)}`)
      .then(res => this.setState({ zoom_logs: res.data, loading: false }))
      .catch(err => console.log(err));

    let query_g = { limit: 100 };
    if (this.props.scope) query_g.scope = this.props.scope;

    this.callApi(`/ams/activities?${stringify(query_g)}`)
      .then(res => this.setState({ activities: res.data, loading: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let query = { };
      if (this.props.scope) query.scope = this.props.scope;

      this.setState({ loading: true });
      this.callApi(`/ams/zoom_ministry?${stringify(query)}`)
        .then(res => this.setState({ zoom_logs: res.data, loading: false }))
        .catch(err => console.log(err));

      let query_g = { limit: 100 };
      if (this.props.scope) query_g.scope = this.props.scope;

      this.callApi(`/ams/activities?${stringify(query_g)}`)
        .then(res => this.setState({ activities: res.data, loading: false }))
        .catch(err => console.log(err));
    }
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleFilter = async () => {
    const query = {
      activity: this.state.selectedActivity,
      activityDate: this.state.activityDate,
    };
    if (this.props.scope) query.scope = this.props.scope
    this.callApi(`/ams/zoom_ministry?${stringify(query)}`)
      .then(res => this.setState({ zoom_logs: res.data, loading: false }))
      .catch(err => console.log(err));
  };


  render() {
    const { activityDate, zoom_logs, activities, loading } = this.state;
    let modResult = [];
    if (zoom_logs && zoom_logs.length > 0) {
      let i = 0;
      zoom_logs.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    let columns = this.columns(constants.activities);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Zoom Logs Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Activity:">
                  <Select
                    showSearch
                    placeholder="Select the activity"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.setState({ selectedActivity: value })}
                  >
                    {activities && activities.map(item =>
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {constants.activities[item.name]}
                      </Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => this.setState({ activityDate: dateString})}
                    defaultValue={activityDate ? moment(activityDate) : null}
                  />
                  <Button
                    type="primary"
                    style={{marginLeft: '10px'}}
                    onClick={this.handleFilter}
                  > Filter</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={20}>
              {(zoom_logs.length === 0) ?
                <div style={{textAlign: 'center'}}>
                  <h3>{`Sorry, no zoom logs found.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here's are the retrieved zoom logs:`}</h3>
                    <Table
                      columns={columns}
                      dataSource={modResult}
                      pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        pageSizeOptions: ['100', '500', '1000']
                      }}
                    />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(ZoomMinistryLogs);