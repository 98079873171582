import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin } from 'antd';
import { stringify } from 'query-string';
import { Component } from 'react';

import _ from "lodash";
import moment from "moment";

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Gathering.css';

const { Option } = Select;
const { WeekPicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};


let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    let searchParams = new URLSearchParams({
      q: value,
    }).toString();
    amsAPI.getUrl(`/ams/members?${searchParams}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

function modalError() {
  Modal.error({
    title: 'Attendance Submission Error',
    content: 'Sorry, but your attendance has already been submitted.',
  });
}

class EditMemberAttendance extends Component {
  state = {
    member: '',
    channel: '',
    attendance: {},
    gathering: {},
    gatherings: [],
    gatheringTypes: [],
    members: [],
    attendanceDateTime: null,
    stateWeekNum: null,
    isLoading: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data }))
      .catch(err => console.log(err));

    let query = { limit: 100 };
    if (this.props.scope) query.scope = this.props.scope;

    this.setState({ isLoading: true });

    const { gatheringId } = this.props.match.params;
    this.getGathering(gatheringId)
      .then(res => this.setState({
        isLoading: false,
        gathering: res.gathering,
        gatherings: [res.gathering]
      }))
      .catch(err => console.log(err));

    const { attendanceId } = this.props.match.params;
    this.callApi(`/ams/member_attendance/${attendanceId}`)
      .then(res => {
        this.setState({ attendance: res.data, stateWeekNum: res.data.weekNum });
        this.getMembers(res.data.memberId._id)
          .then(res => this.setState({ members: res.members, loadingMembers: false }))
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));

  }

  getMembers = async (ids) => {
    const query = {
      filter: JSON.stringify({ id: ids }),
    };
    const response = await amsAPI.getUrl(`/ams/members?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    console.log('weekNum', this.state.stateWeekNum)

    const { gatheringId, attendanceId } = this.props.match.params;
    amsAPI.fetchUrl(`/ams/member_attendance/${attendanceId}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        weekNum: this.state.stateWeekNum
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Attendance successfully submitted.');
          this.setState({ submitting: false });
          this.props.history.push(`/gatherings/${gatheringId}/attendees`);
        } else if (response.error_id === 'ERR001') {
          this.setState({ submitting: false });
          message.error('Wrong pass key.');
        } else if (response.error_id === 'ERR002') {
          this.setState({ submitting: false });
          message.error('All gathering links have been allocated. Please contact your assigned worker.');
        } else if (!_.isEmpty(response.error_id)) {
          this.setState({ submitting: false });
          message.error(response.error_message);
          this.getRequiredInfoFromAPI();
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false, mode: '' });
        modalError();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(`Error submitting attendance. ${ err ? err.message : ''}`);
    });

  };

  handleGatheringChange = (gatheringId) => {
    this.getGathering(gatheringId)
      .then(async res => {
        const { gathering } = res;

        this.setState({
          gathering,
        });
      })
      .catch(err => console.log(err));
  }

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = member => {
    this.setState({ member });
  };

  render() {
    const { gatheringId } = this.props.match.params;
    const {
      attendance, isLoading, stateWeekNum,
      gatherings, gatheringTypes, members, gathering,
    } = this.state;
    const { memberId, submissionDateTime, channel, otherChannel } = attendance;
    let convertedWeekNum = null;
    if (stateWeekNum) {
      convertedWeekNum = moment(stateWeekNum?.substring(0, 4))
        .add(parseInt(stateWeekNum?.substring(4)) - 1, 'weeks');
    }

    const { channels } = gathering;
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    })

    const disableSubmit = _.isEmpty(channel);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Edit Member Attendance Page</h2>
            </Col>
          </Row>
          {isLoading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
                <Form {...formItemLayout}>
                  <Form.Item
                    label="Gathering"
                  >
                    <Select
                      showSearch
                      placeholder="Select a gathering"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 400 }}
                      onChange={this.handleGatheringChange}
                      defaultValue={gatheringId}
                      disabled={true}
                    >
                      {gatherings.map(item =>
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {gatheringTypesMap[item.name]} ({item.type})
                      </Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Week Num"
                  >
                    <WeekPicker
                      format="YYYYww"
                      value={convertedWeekNum}
                      allowClear={false}
                      onChange={(date, dateString) => this.setState({ stateWeekNum: dateString })}
                    />
                  </Form.Item>
                  <Form.Item label={"Attendee"}>
                    <Select
                      showArrow={false}
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      value={memberId && memberId._id ? memberId._id : ''}
                      disabled={true}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Attendance Date & Time"
                  >
                    <DatePicker
                      showToday
                      format="YYYY-MM-DD h:mm a"
                      showTime={{ use12Hours: true, format: "h:mm a" }}
                      value={submissionDateTime ? moment(submissionDateTime) : null }
                      disabled={true}
                    />
                  </Form.Item>
                  {channels &&
                    <Form.Item label={"Attended through"}>
                      <Select
                        placeholder={"Hook-up channel"}
                        dropdownMatchSelectWidth={false}
                        style={{ width: 240 }}
                        value={channel}
                        disabled={true}
                      >
                        {channels.map(name =>
                          <Option key={name} value={name}>{name.charAt(0).toUpperCase() + name.slice(1)}</Option>
                        )}
                      </Select>
                    </Form.Item>
                  }
                  {channel === "others" &&
                    <Form.Item label={"Other Channel"}>
                      <Input
                        value={otherChannel}
                      />
                    </Form.Item>
                  }
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      block
                      onClick={this.handleSubmit}
                      type="primary"
                      htmlType="submit"
                      disabled={disableSubmit}
                    >Update</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            </>
          }
        </div>
      </div>
    );
  }
}

export default EditMemberAttendance;
