import React, { Component } from 'react';
import amsAPI from '../../apis/amsAPI';
import {
  Button, Col, Form, Input, Row, message
} from 'antd';

import 'antd/dist/antd.css';
import './CreateForm.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditMinistry extends Component {
  state = {
    name: '',
    code: '',
    description: '',
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.getMinistry()
      .then(res => {
        this.setState({ ...res.ministry });
      })
      .catch(err => console.log(err));
  }

  getMinistry = async () => {
    const _id = this.props.location.pathname.split('/')[2];
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(`/ams/ministries/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const _id = this.props.location.pathname.split('/')[2];
    await amsAPI.fetchUrl(`/ams/ministries/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        code: this.state.code,
        description: this.state.description,
      }),
    });
    message.success("Ministry successfully updated.");
    this.props.history.push("/ministries")
  };

  render() {
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item
                  label="Ministry Name:"
                >
                  <Input
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Ministry Code:"
                >
                  <Input
                    value={this.state.code}
                    onChange={e => this.setState({ code: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Description:"
                >
                  <Input
                    value={this.state.description}
                    onChange={e => this.setState({ description: e.target.value })}
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">Update</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EditMinistry;
