import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {
  Col, Row, Spin, Table, message, Breadcrumb,
  Button, Icon, Popconfirm,
} from 'antd';
import { KAPIRemittancesFilter } from './';

import * as qs from 'query-string';
import moment from 'moment';

import kapiAPI from '../../../apis/kapiAPI';

import 'antd/dist/antd.css';
import './List.css';

class KAPIemittances extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userMemberId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      churchId: '',
      startDate: '',
      endDate: '',
      message: '',
      filter: {},
      items: [],
      members: [],
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
    };
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Member',
        dataIndex: 'member',
        key: 'member',
        width: 150,
        render: member => <span>{member ? member.name : null}</span>,
      },
      {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
        width: 100,
        render: month => <span>{month}</span>,
      },
      {
        title: 'Dues',
        dataIndex: 'dues',
        key: 'dues',
        width: 100,
        render: dues => <span>{dues}</span>,
      },
      {
        title: 'Cares',
        dataIndex: 'cares',
        key: 'cares',
        width: 100,
        render: cares => <span>{cares}</span>,
      },
      {
        title: 'Do',
        fixed: 'right',
        width: 30,
        key: 'action',
        render: record =>
          <span>
            <div>
              <NavLink
                to={`/kapi/remittances/${record._id}/edit`}
              >
                <Button block type="link">
                  <Icon type={"edit"}/>
                </Button>
              </NavLink>
            </div>
            <NavLink
              to={`/kapi/remittances/${record._id}/history`}
            >
              <Button block type="link">
                <Icon type={"file-text"}/>
              </Button>
            </NavLink>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button block type="link">
                <Icon type={"delete"}/>
              </Button>
            </Popconfirm>
          </span>,
      },
    ];
  }

  handleDelete = async (_id) => {
    await kapiAPI.fetchUrl(`/kapi/remittances/${_id}`, {
      method: 'DELETE',
    });
    message.success("Remittance successfully removed.");
    this.setState({ items: [] })
  };

  getItems = async (query) => {
    this.setState({ loading: true });
    const queryWithStatus = { ...query, status: this.props.status };
    const response = await kapiAPI.getUrl(`/kapi/remittances?${qs.stringify(queryWithStatus)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await kapiAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  handleRangeChange = (date, dateString) => {
    this.setState({ startDate: dateString[0], endDate: dateString[1]})
  }

  setFilter = async (updatedInfo) => {
    const currentInfo = this.state.filter;
    this.setState({
      filter: { ...currentInfo, ...updatedInfo }
    });
  };

  filter = () => {
    const { userMemberId } = this.props;
    const { memberId, receiptNumber, bankAccount, startDate, endDate } = this.state.filter;
    const query = {
      userMemberId, memberId, receiptNumber, startDate, endDate, bankAccount,
    };
    this.getItems(query)
      .then(res => {
        this.setState({
          items: res.remittances,
          loading: false,
          message: res.remittances.length > 0 ? "" : "Sorry, no remittance found."
        })
      })
  }

  downloadFile = async () => {
    const { selectedRowKeys } = this.state;
    kapiAPI.fetchUrl(`/kapi/remittances/pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({ _ids: selectedRowKeys }),
    }).then(async res => {
      if (res.status === 200) {
        const element = document.createElement("a");
        const blob = await res.blob();
        const file = new Blob(
          [blob],
          {type: 'application/pdf'}
        );
        element.href = URL.createObjectURL(file);

        // Save the Data
        const fileName = `Remittance Report_${moment().format('YYYYMMDD_hhmmss')}.pdf`;
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error submitting.');
    });
  }

  render() {
    this.createTableColumns();
    const { filter, items, loading, selectedRowKeys } = this.state;
    const { bankAccount } = filter;
    let modResult = [];
    let i = 0;
    items.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="start" style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={24} lg={24}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/">
                    Home
                  </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/kapi">
                    KAPI Home
                  </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  KAPI Remittances
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>KAPI Remittances List Page</h3>
            </Col>
          </Row>
          <KAPIRemittancesFilter
            setFilter={this.setFilter}
            handleFilter={this.filter}
            downloadFile={this.downloadFile}
            data={selectedRowKeys}
            downloadText={"Download PDFs"}
            bankAccount={bankAccount}
            remitMethod={this.props.remitMethod}
            isLocaleChurchAccount={this.props.isLocaleChurchAccount}
            displayRefresh={false}
          />
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={20}>
              {(items.length === 0) ?
                <div>
                  <h3>{this.state.message}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the remittances queried:`}</h3>
                  <Table
                    rowSelection={this.rowSelection}
                    columns={this.columns}
                    dataSource={modResult}
                    scroll={{ x: 1000 }}
                    pagination={false}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(KAPIemittances);
