import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Button, Col, Row, Spin, Table, Form, Select } from 'antd';
import { stringify } from 'query-string';

import moment from 'moment';
import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './List.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

let timeout;
let currentValue;

function fetch(params, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = params.value;

  async function fake() {
    const { value } = params;
    const query = { q: value };
    amsAPI.getUrl(`/ams/members?${stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class MemberAttendanceSearch extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      localeChurchNames: [],
      selectedRowKeys: [],
      selectedRows: [],
      names: [],
      loadingMembers: false,
    };
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'memberId',
        key: 'memberId',
        sorter: (a, b) => a.memberId.name.localeCompare(b.memberId.name),
        render: (text, record) => {
          return (
            <span>
            {(record.memberId && record.memberId.name) ? record.memberId.name : ""}
            </span>
          )
        },
      },
      {
        title: 'Receipt Number',
        dataIndex: 'receiptNumber',
        key: 'receiptNumber',
        render: (text, record) => (
          <span>
            {record.receiptNumber ?
              record.receiptNumber
              :
              null
            }
          </span>
        ),
      },
      {
        title: 'Gathering',
        dataIndex: 'gatheringId',
        key: 'gatheringId',
        render: (text, record) => (
          <span>
            {record.gatheringId ?
              <span>
                {constants.gatherings[record.gatheringId.name]}<br/>
                {moment(record.gatheringId.startDateTime).format("MMM.DD(ddd), h:mmA")}
              </span>
              :
              null
            }
          </span>
        ),
      },
    ];
  }

  getAttendees = async (query) => {
    this.setState({ loadingMembers: true });
    query = { ...query, sortApproved: true };
    const response = await amsAPI.getUrl(`/ams/member_attendance/attendees?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendees({  })
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  handleFilter = async () => {
    const query = {
      name: this.state.name
    };
    this.setState({ loadingMembers: true });
    this.getAttendees(query)
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  };

  handleSearch = (value, gatheringId) => {
    if (value) {
      const params = { value, gatheringId };
      fetch(params, data => {
        this.setState({ names: data })
      });
    } else {
      this.setState({ names: [] });
    }
  };

  handleChange = name => {
    this.setState({ name });
  };

  render() {
    this.createTableColumns();
    const { gatheringId } = this.props.match.params;
    const { members, names, loadingMembers } = this.state;
    let modResult = [];
    let i = 0;
    members.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Name:">
                <Select
                      showSearch
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={(value) => this.handleSearch(value, gatheringId)}
                      onChange={this.handleChange}
                      notFoundContent={null}
                    >
                      {names.map(item => {
                        return (
                          <Option key={item._id} value={item.name}>
                            {item.name}
                          </Option>
                        )
                      })}
                  </Select>
                  <Button
                    type="primary"
                    style={{marginLeft: '10px'}}
                    onClick={this.handleFilter}
                  > Filter</Button>  
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loadingMembers ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(members.length === 0) ?
                <div>
                  <h3>{`Sorry, but no attendance found for selected member.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the attendance for the selected member:`}</h3>
                  <Table
                    rowSelection={this.rowSelection}
                    pagination={false}
                    columns={this.columns}
                    dataSource={modResult}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(MemberAttendanceSearch);
