import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Button, Col, Row, Spin, Table, Popconfirm, Form, Select, message } from 'antd';
import moment from 'moment';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './List.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class Links extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      links: [],
      gatherings: [],
      loadingLinks: false,
      loadingGatherings: false,
    };
  }
  createTableColumns = async () => {
    this.columns = [
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        sorter: (a, b) => a.memberId.name.localeCompare(b.memberId.name),
        render: (text, record) => (
          <span>{record.url}</span>
        ),
      },
      {
        title: 'Is Allocated',
        dataIndex: 'isAllocated',
        key: 'isAllocated',
        render: (text, record) => (
          <span>
            {record.isAllocated ? "Y": "N"}
          </span>
        ),
      },
      {
        title: 'Is Accessed',
        dataIndex: 'isAccessed',
        key: 'isAccessed',
        render: (text, record) => (
          <span>
            {record.isAccessed ? "Y": "N"}
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.setState({ loadingGatherings: true, loadingLinks: true });
    this.getGatherings()
      .then(res => this.setState({ gatherings: res.gatherings, loadingGatherings: false }))
      .catch(err => console.log(err));
    this.getLinks()
      .then(res => this.setState({ links: res.link, loadingLinks: false }))
      .catch(err => console.log(err));
  }

  getLinks = async () => {
    this.setState({ loadingLinks: true });
    const { gatheringId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}/links`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGatherings = async () => {
    const response = await amsAPI.getUrl(`/ams/gatherings`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (_id) => {
    await amsAPI.fetchUrl(`/ams/links/${_id}`, {
      method: 'DELETE',
    });
    message.success("Link successfully removed.");
    this.props.history.push(`/links`);
  };

  render() {
    this.createTableColumns();
    const { links, loadingLinks, gatherings, selectedGathering, loadingGatherings } = this.state;
    const loading = loadingLinks || loadingGatherings;

    let modResult = [];
    let i = 0;
    links.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Gathering:">
                  <Select
                      showSearch
                      placeholder="Select the gathering"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.setState({ selectedGathering: value })}
                      value={selectedGathering}
                    >
                    {gatherings.map(item => 
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {constants.gatherings[item.name]} ({item.type})
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
            {(links.length === 0) ?
              <div>
                <h3>{`Sorry, but there are no attendees for this gathering.`}</h3>
              </div>
            :
              <div>
                <h3>{`Here are the list of attendees for :`}</h3>
                <Table columns={this.columns} dataSource={modResult} />
              </div>
            }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Links);
