import React, { Component } from 'react';
import amsAPI from '../../apis/amsAPI';
import {
  Form, Input, Button, Row, Col, Spin,
  PageHeader, Select,
} from 'antd';
import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditLocaleChurch extends Component {

  constructor(props) {
    super(props)
    this.state = {
      record: {},
      countries: [],
      loadingRecord: false,
      loadingLocaleInfo: false,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingLocaleInfo: true });
    this.getLocaleInfo()
      .then(res => {
        this.setState({ record: res.locale, loadingLocaleInfo: false })
      })
      .catch(err => console.log(err));
    this.callApi('/ams/countries')
      .then(res => {
        this.setState({ countries: res.data })
      });
  }

  getLocaleInfo = async () => {
    const { localeId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/locale_churches/${localeId}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { localeId } = this.props.match.params;
    const { record } = this.state;
    await amsAPI.fetchUrl(`/ams/locale_churches/${localeId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...record }),
    });
    this.props.history.push('/locale_churches');
  };

  render() {
    const { record, countries, loadingLocaleInfo } = this.state;
    const { name, code, country, location, address } = record;
    const disableSubmit = !name;
    const loading = loadingLocaleInfo;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Locale Name:"
                  >
                    <Input
                      onChange={e => this.updateRecord({ name: e.target.value })}
                      defaultValue={name}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Code:"
                  >
                    <Input
                      onChange={e => this.updateRecord({ code: e.target.value })}
                      defaultValue={code}
                    />
                  </Form.Item>
                  <Form.Item label="Country:">
                    <Select
                      placeholder="Select a country"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateRecord({ country: value })}
                      disabled={countries.length === 0}
                      value={country}
                    >
                      {countries.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Location"
                  >
                    <Input
                      onChange={e => this.updateRecord({ location: e.target.value })}
                      defaultValue={location}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                  >
                    <Input
                      onChange={e => this.updateRecord({ address: e.target.value })}
                      defaultValue={address}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Update"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default EditLocaleChurch;
