import {
  Button, Col, DatePicker, Form, Icon, Input, Modal, PageHeader, Row, Select, Spin, Switch,
  Upload
} from 'antd';
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { withRouter } from "react-router";

import MusicMinistryMemberForm from './MusicMinistryMemberForm';

import { stringify } from 'query-string';
import * as constants from '../../helpers/constants';
import * as functions from '../../helpers/functions';

import 'antd/dist/antd.css';
import amsAPI from '../../apis/amsAPI';
import financeAPI from '../../apis/financeAPI';
import './CreateForm.css';

const Option = Select.Option;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const query = { name: value};
    amsAPI.getUrl(`/ams/locale_churches/simple?${stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class MemberForm extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userMemberId: PropTypes.string.isRequired,
  };

  state = {
    ministries: [],
    localeChurches: [],
    assignedLocaleChurches: [],
    bankAccounts: [],
    roles: [],
    picsFileList: [],
    profilePic: {},
    member: {},
    selectedMinistryName: '',
    selectedMinistryNames: [],
    loadingMember: false,
    loadingMinistries: false,
    loadingLocaleChurches: false,
    previewVisible: false,
    previewImage: '',
    rotation: 0,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingMember: true, loadingMinistries: true, loadingLocaleChurches: true });
    this.callApi('/ams/roles')
      .then(res => {
        this.setState({ roles: res.data })
      })
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.ministries, loadingMinistries: false })
      })
      .catch(err => console.log(err));
    this.callApi('/ams/locale_churches/simple')
      .then(res => {
        this.setState({
          localeChurches: res.data,
          assignedLocaleChurches: res.data,
          loadingLocaleChurches: false
        })
      })

    this.getRecordImage('profilePic')
      .then(res => {
        this.setState({ profilePic: res.data ? res.data.profilePic : {} });
      })

    const { memberId } = this.props.match.params;
    this.callApi(`/ams/members/${memberId}`)
      .then(res => {
        this.setState({ member: res.member, loadingMember: false });
        const { member } = this.state;
        if (member.ministryId) {
          this.setState({ selectedMinistryName: member.ministryId.name });
        }
      })
      .catch(err => console.log(err));

    if (this.props.services.fin.isAvailable) {
      this.callFinanceApi("/fin/bank_accounts")
        .then(res => {
          this.setState({ bankAccounts: res.data });
        })
        .catch(err => console.log(err));
    }

  }

  handleSubmit = async e => {
    e.preventDefault();
    const { member, picsFileList } = this.state;
    const { userMemberId} = this.props;
    const { memberId } = this.props.match.params;

    const formData = new FormData();
    formData.append('userMemberId', userMemberId);
    Object.entries(member).forEach(([k,v]) => {
      if (k !== "profilePic") {
        if (typeof v === "object") {
          if (_.isEmpty(v) || _.isEmpty(v._id)) {
            formData.append(k, JSON.stringify(v));
          } else {
            formData.append(k, v._id);
          }

        } else {
          formData.append(k, v);
        }
      }
    })
    if (picsFileList[0]) formData.append('profilePic', picsFileList[0]);

    await amsAPI.fetchMulter(`/ams/members/${memberId}`, {
      method: 'PATCH',
      body: formData,
      headers: {
        'Cache-Control': 'no-cache'
      },
    });
    this.props.history.push('/members');
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  callFinanceApi = async (url) => {
    const response = await financeAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  handleMinsitrySelect = async (labelKeyValue) => {
    if (!_.isEmpty(labelKeyValue)) {
      this.updateMember({ ministryId: { _id: labelKeyValue.key } })
      this.setState({ selectedMinistryName: labelKeyValue.label });
    } else {
      this.updateMember({ ministryId: null })
      this.setState({ selectedMinistryName: "" });
    }
  };

  handleMinsitriesSelect = async (value) => {
    this.updateMember({ ministries: value })
  };

  handleLocaleSelect = async (value) => {
    this.updateMember({ localeChurchId: value })
  };

  handleLocaleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ localeChurches: data })
      });
    } else {
      this.setState({ localeChurches: [] });
    }
  };

  handleAssignedLocaleSelect = async (value) => {
    this.updateMember({ assignedLocales: value })
  };

  handleAssignedLocaleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ assignedLocaleChurches: data })
      });
    } else {
      this.setState({ assignedLocaleChurches: [] });
    }
  };

  handleRoleSelect = async (value) => {
    this.updateMember({ roles: value })
  };

  updateMember = async (updatedInfo) => {
    const currenMemberInfo = this.state.member;
    this.setState({
      member: { ...currenMemberInfo, ...updatedInfo }
    });
  };

  getRecordImage = async (image) => {
    const { memberId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/members/${memberId}/img?image=${image}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    const base64Flag = `data:${file.contentType};base64,`;
    const imageStr = functions.arrayBufferToBase64(file.data.data);
    const image = base64Flag + imageStr;

    this.setState({
      previewImage: image,
      previewVisible: true,
    });
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if(newRotation === 360){
      newRotation = 0;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    const nationalities = constants.nationalities;
    const {
      localeChurches, assignedLocaleChurches, member,
      loadingMinistries, loadingMember, loadingLocaleChurches,
      selectedMinistryName, bankAccounts, ministries, roles,
      profilePic, picsFileList, previewVisible, previewImage, rotation,
    } = this.state;
    const { isAdmin, isVisiting, isNotify, isMonitored, isKapiAssociate, isEnableOtp, isUnderProbationary } = member;
    const loading = loadingMinistries || loadingMember || loadingLocaleChurches;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.picsFileList.indexOf(file);
          const newFileList = state.picsFileList.slice();
          newFileList.splice(index, 1);
          return {
            picsFileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          picsFileList: [file],
        }));
        return false;
      },
      fileList: picsFileList,
      accept: "image/*",
    };

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Please select a locale">
                    <Select
                      showSearch
                      placeholder="Search a locale"
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      allowClear={true}
                      showArrow={false}
                      onChange={this.handleLocaleSelect}
                      onSearch={this.handleLocaleSearch}
                      notFoundContent={null}
                      defaultValue={
                        member.localeChurchId ? member.localeChurchId._id : null
                      }
                    >
                      {localeChurches && localeChurches.map(item => {
                        return <Option key={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name"
                  >
                    <Input
                      defaultValue={member.name}
                      onChange={(e) => this.updateMember({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label={"Upload Picture"}>
                    <Upload {...props}>
                      <Button>
                        <Icon type="upload" />Select File
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item label="Preview Picture">
                    <Button
                      onClick={(e) => this.handlePreview(profilePic)}
                      disabled={_.isEmpty(profilePic)}
                    >
                      <Icon type="eye"/>Preview picture
                    </Button>
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="redo"
                        onClick={this.rotate}
                      />
                      <img
                        alt="example"
                        style={{ width: '100%', transform: `rotate(${rotation}deg)` }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                  <Form.Item
                    label="Is Visiting Brethren"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={isVisiting}
                      onChange={(checked) => this.updateMember({ isVisiting: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Monitored"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={isMonitored}
                      onChange={(checked) => this.updateMember({ isMonitored: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is KAPI Associate"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={isKapiAssociate}
                      onChange={(checked) => this.updateMember({ isKapiAssociate: checked })}
                    />
                  </Form.Item>
                  <Form.Item label="Nationality">
                    <Select
                      showSearch
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => this.updateMember({ nationality: value })}
                      defaultValue={member.nationality}
                      allowClear={true}
                    >
                      {nationalities.map(item =>
                        <Option key={item} value={item}>{item}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Date of Birth"
                  >
                    <DatePicker
                      placeholder="Date of Birth"
                      format="YYYY-MM-DD"
                      defaultValue={member.birthDate ? moment(member.birthDate) : null}
                      onChange={(value) => this.updateMember({ birthDate: value?.format("YYYY-MM-DD") })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Church Id"
                  >
                    <Input
                      defaultValue={member.churchId}
                      onChange={e => this.updateMember({ churchId: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Baptism Date & Time"
                  >
                    <DatePicker
                      placeholder="Baptism Date & Time"
                      format="YYYY-MM-DD h:mm a"
                      showTime={{ use12Hours: true, format: "h:mm a" }}
                      defaultValue={member.baptismDate ? moment(member.baptismDate) : null}
                      onChange={(value) => this.updateMember({ baptismDate: value })}
                    />
                  </Form.Item>
                  <Form.Item label="Ministry">
                    <Select
                      showSearch
                      allowClear={true}
                      placeholder="Select a ministry"
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleMinsitriesSelect}
                      defaultValue={member.ministries ? member.ministries.map(i => i._id) : null}
                      mode="multiple"
                    >
                      {ministries && ministries.map(ministry => {
                        return <Option key={ministry._id} value={ministry._id}>{ministry.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item
                    label="Is Ministry Trainee"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => this.updateMember({ isUnderProbationary: checked })}
                      defaultChecked={isUnderProbationary}
                    />
                  </Form.Item> */}
                  <Form.Item
                    label="Group Name"
                  >
                    <Input
                      defaultValue={member.groupName}
                      onChange={e => this.updateMember({ groupName: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                  >
                    <Input
                      defaultValue={member.email}
                      onChange={e => this.updateMember({ email: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Receive Notifications"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={isNotify}
                      onChange={(checked) => this.updateMember({ isNotify: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Enable OTP"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={isEnableOtp}
                      onChange={(checked) => this.updateMember({ isEnableOtp: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="User Link"
                  >
                    <Input
                      defaultValue={member.lineLink}
                      onChange={e => this.updateMember({ lineLink: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Admin"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={isAdmin}
                      onChange={(checked) => this.updateMember({ isAdmin: checked })}
                    />
                  </Form.Item>
                  <Form.Item label="Roles">
                    <Select
                      placeholder="Please select roles"
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleRoleSelect}
                      defaultValue={member.roles}
                      allowClear={true}
                      mode={"multiple"}
                    >
                      {roles.map(({ name, code }) =>
                        <Option key={code} value={code}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  {this.props.services.fin.isAvailable &&
                    <Form.Item label={"Bank Account"}>
                      <Select
                        dropdownMatchSelectWidth={false}
                        onChange={value => this.updateMember({ monitoredBankAccounts: value })}
                        defaultValue={member.monitoredBankAccounts}
                        mode={"multiple"}
                      >
                        {bankAccounts.map(item => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item label="Assigned Locale/s">
                    <Select
                      showSearch
                      placeholder="Search a locale"
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      allowClear={true}
                      showArrow={false}
                      onChange={this.handleAssignedLocaleSelect}
                      onSearch={this.handleAssignedLocaleSearch}
                      notFoundContent={null}
                      defaultValue={member.assignedLocales}
                      mode={"multiple"}
                    >
                      {assignedLocaleChurches && assignedLocaleChurches.map(item => {
                        return <Option key={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {(selectedMinistryName && selectedMinistryName.indexOf('Music Ministry') >= 0) &&
                    <MusicMinistryMemberForm
                      member={member}
                      updateMember={this.updateMember}
                    />
                  }
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Update</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(MemberForm);
