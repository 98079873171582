import {
  Button, Col, Form, Row, Select
} from 'antd';
import * as qs from 'query-string';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ReportsHomeSabbathCelebrants extends Component {
  state = {
    selectedMonth: (new Date()).getMonth() + 1,
    nationality: '',
    ministries: [],
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
  }

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  render() {
    const { months, nationalities } = constants;
    const {
      ministries, nationality,
      selectedMonth, selectedMinistry,
    } = this.state;

    let query = {
      selectedMonth,
      nationality,
      ministryId: selectedMinistry,
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Sabbath Celebrants Generation Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h4>Please select the month you would like to generate a celebrants list.</h4>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Month">
                  <Select
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => this.setState({ selectedMonth: value })}
                    allowClear={true}
                    defaultValue={selectedMonth}
                  >
                    {months.map(({label, value}) =>
                      <Option key={value} value={value}>{label}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Nationality">
                  <Select
                    showSearch
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => this.setState({ nationality: value })}
                    allowClear={true}
                    defaultValue={this.state.nationality}
                  >
                    {nationalities.map(item =>
                      <Option key={item} value={item}>{item}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Ministry">
                  <Select
                    placeholder="Select a ministry"
                    dropdownMatchSelectWidth={false}
                    allowClear={true}
                    onChange={value => this.setState({ selectedMinistry: value })}
                    value={selectedMinistry}
                  >
                    {ministries.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <NavLink to={`/reports/sabbath_celebrants_by_month?${qs.stringify(query)}`}>
                <Button
                  block
                  type="primary"
                  disabled={!selectedMonth}
                >
                  Next
                </Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ReportsHomeSabbathCelebrants;
