import React, { Component } from 'react';
import { Col, Row, Table, Spin, Button, Icon, Checkbox } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';

import * as constants from '../../helpers/constants';
import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Report.css';

class AttendanceReportByGathering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gathering: {},
      attendance: [],
      otherLocaleAttendance: [],
      gatheringTypes: [],
      ministries: [],
      localeChurches: [],
      isGenerateIds: false,
      isGenerateIdsAll: false,
      loadingAttendance: false,
    };

    this.columns = (localeChurchesMap) => {
      return [
        {
          title: 'No',
          dataIndex: 'key',
          key: 'key',
          render: key => <span>{key}</span>
        },
        {
          title: 'Locale',
          dataIndex: 'memberId',
          key: 'memberId.localeChurchId',
          render: memberId => <span>{(memberId && memberId.localeChurchId) ? memberId.localeChurchId.name : ""}</span>,
        },
        {
          title: 'Member',
          dataIndex: 'memberId',
          key: 'memberId.name',
          render: memberId => <span>{memberId ? memberId.name : ""}</span>,
        },
        {
          title: 'Church Id',
          dataIndex: 'memberId',
          key: 'memberId.churchId',
          render: memberId => <span>{memberId ? memberId.churchId : ""}</span>,
        },
        {
          title: 'Receipt No.',
          dataIndex: 'receiptNumber',
          key: 'receiptNumber',
          render: receiptNumber => <span>{receiptNumber}</span>,
        },
        {
          title: 'Submission',
          dataIndex: 'submissionDateTime',
          key: 'submissionDateTime',
          render: (text, record) => (
            <span>
              {record.submissionDateTime ?
                `${moment(record.submissionDateTime).format("MMM.DD(ddd), h:mmA")}`
                :
                null
              }
            </span>
          ),
        },
        {
          title: 'Channel',
          dataIndex: 'channel',
          key: 'channel',
          render: (text, record) => (
            <span>{constants.channels[record.channel]}{record.channel && record.channel === "others" ? `(${record.otherChannel})` : ""}</span>
          ),
        },
        {
          title: 'Attended At',
          dataIndex: 'attendedLocaleChurch',
          key: 'attendedLocaleChurch',
          render: (text, record) => {
            let { channel, attendedLocaleChurch, attendedLocaleChurchExt } = record;
            let attendedAt = "-";
            if (channel == "locale") {
              attendedAt = localeChurchesMap[attendedLocaleChurch];
            } else if (channel == "locale-ext") {
              attendedAt = attendedLocaleChurchExt;
            };
            return attendedAt;
          }
        },
        {
          title: 'IP Address',
          dataIndex: 'ipAddress',
          key: 'ipAddress',
          render: (text, record) => (
            <span>{record.ipAddress}</span>
          ),
        },
      ];
    };
  }

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
    this.callApi('/ams/locale_churches')
      .then(res => {
        this.setState({ localeChurches: res.data })
      });

    this.getAttendance()
      .then(res => {
        let modResult = res.result.sort((a, b) => {
          if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
          return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
        });
        this.setState({
          attendance: modResult,
          otherLocaleAttendance: res.otherLocaleResult,
          loadingAttendance: false
        });
      })
      .catch(err => console.log(err));

    const query = qs.parse(this.props.location.search);
    const { gatheringId } = query;
    this.getGatheringInfo(gatheringId)
      .then(res => this.setState({ gathering: res.gathering }))
      .catch(err => console.log(err));
  }

  getAttendance = async () => {
    this.setState({ loadingAttendance: true })
    const query = qs.parse(this.props.location.search);
    const response = await amsAPI.getUrl(`/ams/locale_attendance/by_gathering?${qs.stringify(query)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGatheringInfo = async (gatheringId) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async () => {
    this.setState({ loadingMembers: true });
    const response = await amsAPI.getUrl(`/ams/members`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendance()
      .then(res => {
        let modResult = res.result.sort((a, b) => {
          if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
          return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
        });
        this.setState({
          attendance: modResult,
          otherLocaleAttendance: res.otherLocaleResult,
          loadingAttendance: false
        });
      })
      .catch(err => console.log(err));
  }

  downloadTxtFile = async () => {
    const {
      attendance, otherLocaleAttendance, gathering, gatheringTypes,
      isGenerateIds, isGenerateIdsAll, localeChurches,
    } = this.state;
    const gatherings = {};
    gatheringTypes.forEach(item => {
      gatherings[item.code] = item.name;
    })
    const localeChurchesMap = {};
    localeChurches.forEach(item => {
      localeChurchesMap[item._id] = item.name;
    })

    let modResult = attendance.sort((a, b) => {
      return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
    });
    modResult = modResult.concat(otherLocaleAttendance);

    let outputTxt = "";
    const reportDate = moment(gathering.startDateTime).format("h:mmA | dddd | MMMM DD, YYYY");
    outputTxt = outputTxt + "ATTENDANCE REPORT FOR HOOKUP\r\n";
    outputTxt = outputTxt + gatherings[gathering.name] + "\r\n";
    outputTxt = outputTxt + reportDate + "\r\n";

    if (isGenerateIdsAll) {
      outputTxt = outputTxt + "\r\n";
      modResult.forEach(item => {
        outputTxt = outputTxt + item.memberId.churchId + "\r\n";
      });

    } else if (gathering.name === "kgm") {
      let localeChurch = "";
      let counter = 1;

      let kapiAssocs = modResult.filter(x => x.isKapiAssociate);
      let nonKapiAssocs = modResult.filter(x => !x.isKapiAssociate);

      outputTxt = outputTxt + "\r\n";
      outputTxt = outputTxt + "### KAPI ASSOCIATES ###\r\n";

      kapiAssocs.forEach(item => {
        const { memberId, submissionDateTime } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== memberId.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = memberId.localeChurchId.name;
          outputTxt = outputTxt + memberId.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let member = `  ${counter}. ${memberId.name}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        outputTxt = outputTxt + member + "\r\n";
        counter++;
      });

      outputTxt = outputTxt + "\r\n";
      outputTxt = outputTxt + "### NON-KAPI ASSOCIATES ###\r\n";

      localeChurch = "";
      counter = 1;
      nonKapiAssocs.forEach(item => {
        const { memberId, submissionDateTime, attendedLocaleChurch, attendedLocaleChurchExt, channel } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== memberId.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = memberId.localeChurchId.name;
          outputTxt = outputTxt + memberId.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let attendedAt = "";
        if (channel == "locale") {
          attendedAt = localeChurchesMap[attendedLocaleChurch];
        } else if (channel == "locale-ext") {
          attendedAt = attendedLocaleChurchExt;
        }

        let member = `  ${counter}. ${memberId.name}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime} (@${attendedAt})`;
        outputTxt = outputTxt + member + "\r\n";
        counter++;
      });

    } else {
      let localeChurch = "";
      let counter = 1;
      modResult.forEach(item => {
        const { memberId, submissionDateTime, attendedLocaleChurch, attendedLocaleChurchExt, channel } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== memberId.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = memberId.localeChurchId.name;
          outputTxt = outputTxt + memberId.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let attendedAt = "";
        if (channel == "locale") {
          attendedAt = localeChurchesMap[attendedLocaleChurch];
        } else if (channel == "locale-ext") {
          attendedAt = attendedLocaleChurchExt;
        }

        let member = "";
        if (isGenerateIds && memberId.isVisiting) {
          member = `  ${counter}. ${memberId.churchId}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime} (@${attendedAt})`;
        } else {
          member = `  ${counter}. ${memberId.name}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime} (@${attendedAt})`;
        }
        outputTxt = outputTxt + member + "\r\n";
        counter++;
      });
    }

    let channelSummary = { }
    modResult.forEach(item => {
      let { channel, otherChannel } = item;
      if (channel) {
        let channelKey = channel === "others" ? `${channel} (${otherChannel})` : channel;
        if (channelSummary[channelKey]) {
          channelSummary[channelKey] = channelSummary[channelKey] + 1;
        } else {
          channelSummary[channelKey] = 1
        }
      }
    });

    outputTxt = outputTxt + "\r\n";
    outputTxt = outputTxt + "CHANNEL SUMMARY\r\n";
    const channelEntries = Object.entries(channelSummary);
    channelEntries.forEach(([key, value]) => {
      outputTxt = outputTxt + `  ${constants.channels[key]}: ${value}\r\n`;
    })

    const element = document.createElement("a");
    const file = new Blob([outputTxt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Attendance Report_${moment().format('YYYYMMDD_hhmmss')}.txt`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    const { attendance, otherLocaleAttendance, loadingAttendance, localeChurches } = this.state;
    const loading = loadingAttendance;
    const hasResult = attendance.length > 0 || otherLocaleAttendance.length > 0;

    const localeChurchesMap = {};
    localeChurches.forEach(item => {
      localeChurchesMap[item._id] = item.name;
    })
    let columns = this.columns(localeChurchesMap);

    let modResult = [];
    if (hasResult) {
      let i = 1;
      attendance.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
      otherLocaleAttendance.forEach(item => {
        modResult.push({ ...item, key: i++});
      })
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Gathering Attendance Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                onClick={this.downloadTxtFile}
                disabled={!hasResult}
              > Generate Text</Button>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Checkbox
                  onChange={(e) => this.setState({ isGenerateIdsAll: e.target.checked })}
                  disabled={this.state.isGenerateIds}
                >Generate church IDs only for ALL</Checkbox><br/>
                <Checkbox
                  onChange={(e) => this.setState({ isGenerateIds: e.target.checked })}
                  disabled={this.state.isGenerateIdsAll}
                >Generate church IDs only for visiting brethren</Checkbox>
              </div>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(modResult && modResult.length === 0) ?
                <h2>{`No attendance available for selected gathering.`}</h2>
              :
                <div>
                  <h3>{`Here's the attendance for the selected gathering:`}</h3>
                  <Table pagination={false} columns={columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default AttendanceReportByGathering;
