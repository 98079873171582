import React, { Component } from 'react';
import amsAPI from '../../apis/amsAPI';
import {
  Button, Col, Form, Input, Row, Select, Spin,
} from 'antd';
import ReactGA from 'react-ga';

import 'antd/dist/antd.css';
import './CreateForm.css';

const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class CreateChurchGroup extends Component {
  state = {
    churchLocales: [],
    loadingChurchLocales: false,
    ministries: [],
    loadingMinistries: false,
    selectedLocale: '',
    selectedMinistry: '',
    response: [],
    name: '',
    responseToPost: '',
  };

  componentDidMount() {
    this.getChurchLocalesAndMinistries();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getChurchLocalesAndMinistries();
    }
  }

  getChurchLocalesAndMinistries = async () => {
    let storedLocaleId = localStorage.getItem('localeChurchId');
    this.getChurchLocalesApi()
      .then(res => {
        this.setState({ churchLocales: res.locales, loadingChurchLocales: false })
        if (storedLocaleId) {
          this.setState({ selectedLocale: storedLocaleId })
        }
      })
      .catch(err => console.log(err));

      this.getMinistriesApi()
      .then(res => {
        this.setState({ ministries: res.ministries, loadingMinistries: false })
      })
      .catch(err => console.log(err));
  }

  getChurchLocalesApi = async () => {
    this.setState({ loadingChurchLocales: true });
    const response = await amsAPI.getUrl('/ams/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMinistriesApi = async () => {
    this.setState({ loadingMinistries: true });
    const response = await amsAPI.getUrl('/ams/ministries')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleLocaleSelect = async (value) => {
    ReactGA.event({
      category: 'Create Church Group',
      action: 'locale select'
    });
    localStorage.setItem('localeChurchId', value);
    this.setState({ selectedLocale: value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const response = await amsAPI.fetchUrl('/ams/church_groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        name: this.state.name, 
        localeChurchId: this.state.selectedLocale,
        ministryId: this.state.selectedMinistry
      }),
    });
    const body = await response.text();
    this.setState({ responseToPost: body });
  };

  render() {
    const { churchLocales, ministries, selectedLocale, selectedMinistry, loadingChurchLocales, loadingMinistries } = this.state;

    if (loadingChurchLocales || loadingMinistries) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="Locale:">
                  <Select
                      showSearch
                      placeholder="Please select a locale the group belongs to..."
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleLocaleSelect}
                      value={selectedLocale}
                    >
                      {churchLocales.map(locale => {
                        return <Option key={locale._id} value={locale._id}>{locale.name}</Option>
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label="Ministry:">
                  <Select
                      showSearch
                      placeholder="Please select the ministry the group belongs to..."
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.setState({ selectedMinistry: value })}
                      value={selectedMinistry}
                    >
                      {ministries.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Church Group Name:"
                >
                  <Input
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">Register</Button>
                  <p>{this.state.responseToPost}</p>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateChurchGroup;
