import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Form, Select, Row, Col, PageHeader, Spin, DatePicker,
  Input, Button, Modal, Icon,
} from 'antd';
import RemitMethod from './RemitMethod';

import querystring from 'querystring';
import moment from 'moment';
import _ from 'lodash';
import * as functions from '../../../helpers/functions';
import * as constants from '../../../helpers/constants';

import amsAPI from '../../../apis/amsAPI';
import financeAPI from '../../../apis/financeAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
    lg: { span: 16 },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class ShowRemittance extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)
    this.state = {
      remitters: [],
      recipients: [],
      bankAccounts: [],
      record: {},
      remitImg: {},
      receiptImg: {},
      transferImgs: [],
      submitting: false,
      loadingRecord: true,
      loadingRemitImg: true,
      loadingReceiptImg: true,
      loadingTransferImgs: true,
      previewVisible: false,
      previewImage: '',
      rotation: 0,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true, loadingBankAccounts: true });
    this.getBankAccounts()
      .then(res => {
        this.setState({ bankAccounts: res.data, loadingBankAccounts: false });
      })
      .catch(err => console.log(err));
    this.getRecord()
      .then(res => {
        this.setState({ record: res.data, loadingRecord: false });
      })
    this.getRecordImage('remitImg')
      .then(res => {
        this.setState({ remitImg: res.data ? res.data.remitImg : {}, loadingRemitImg: false });
      })
    this.getRecordImage('receiptImg')
      .then(res => {
        this.setState({ receiptImg: res.data ? res.data.receiptImg : {}, loadingReceiptImg: false });
      })
    this.getRecordImage('transferImgs')
      .then(res => {
        this.setState({ transferImgs: res.data ? res.data.transferImgs : [], loadingTransferImgs: false });
      })
  }

  getBankAccounts = async () => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getRecord = async () => {
    const { _id } = this.props.match.params;
    const response = await financeAPI.getUrl(`/fin/remittances/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getRecordImage = async (image) => {
    const { _id } = this.props.match.params;
    const response = await financeAPI.getUrl(`/fin/remittances/${_id}/img?image=${image}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSearchRemitter = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ remitters: data })
      });
    } else {
      this.setState({ remitters: [] });
    }
  };

  handleSearchRecipient = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ recipients: data })
      });
    } else {
      this.setState({ recipients: [] });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    const base64Flag = `data:${file.contentType};base64,`;
    const imageStr = functions.arrayBufferToBase64(file.data.data);
    const image = base64Flag + imageStr;

    this.setState({
      previewImage: image,
      previewVisible: true,
    });
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if(newRotation === 360){
      newRotation = 0;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    const {
      record, recipients, remitters, bankAccounts,
      loadingRecord, loadingBankAccounts, loadingRemitImg, loadingReceiptImg, loadingTransferImgs,
      previewVisible, previewImage, remitImg, receiptImg, transferImgs, rotation,
    } = this.state;
    const {
      receiptNumber, remitter, remitterContactNum, remitterEmail,
      remittanceDate, receiptDate, notes, status,
    } = record;
    const remittanceStatuses = Object.entries(constants.remittanceStatuses);

    const loading =  loadingRecord || loadingBankAccounts;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label={"Receipt Number"}>
                    <Input
                      defaultValue={receiptNumber}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label="Remitter">
                    <Select
                        showSearch
                        dropdownMatchSelectWidth={false}
                        mode={this.state.mode}
                        optionFilterProp="value"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.handleSearchRemitter}
                        onChange={(value) => this.updateRecord({remitter: value})}
                        notFoundContent={null}
                        defaultValue={remitter ? remitter.name : null}
                        disabled={true}
                      >
                        {remitters.map(item => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {`${item.churchId} ${item.name}`}
                            </Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item label={"Contact Number"}>
                    <Input
                      onChange={(e) => this.updateRecord({ remitterContactNum: e.target.value })}
                      defaultValue={remitterContactNum ? remitterContactNum : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Contact Email"}>
                    <Input
                      onChange={(e) => this.updateRecord({ remitterEmail: e.target.value })}
                      defaultValue={remitterEmail ? remitterEmail : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <RemitMethod
                    handleSearchRecipient={this.handleSearchRecipient}
                    updateRecord={this.updateRecord}
                    data={record}
                    recipients={recipients}
                    bankAccounts={bankAccounts}
                    disabled={true}
                  />
                  <Form.Item label={"Remittance Date"}>
                    <DatePicker
                      placeholder={null}
                      onChange={(value) => this.updateRecord({ remittanceDate: value })}
                      defaultValue={remittanceDate ? moment(remittanceDate) : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Receipt Date"}>
                    <DatePicker
                      placeholder={null}
                      onChange={(value) => this.updateRecord({ receiptDate: value })}
                      defaultValue={receiptDate ? moment(receiptDate) : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Status"}>
                    <Select
                      placeholder={status ? null : "Status"}
                      dropdownMatchSelectWidth={false}
                      onChange={(e) => this.updateRecord({ status: e.target.value })}
                      defaultValue={status ? status : null}
                      disabled={true}
                    >
                      {remittanceStatuses.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Breakdown/Notes:">
                    <TextArea
                      autosize={{ minRows: 7 }}
                      onChange={(e) => this.updateRecord({ notes: e.target.value })}
                      defaultValue={notes ? notes : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label="Receipts:">
                    <Button
                      onClick={(e) => this.handlePreview(remitImg)}
                      disabled={_.isEmpty(remitImg)}
                      loading={loadingRemitImg}
                    >
                      <Icon type="eye"/>Preview Remit Image
                    </Button>
                    <Button
                      onClick={(e) => this.handlePreview(receiptImg)}
                      disabled={_.isEmpty(receiptImg)}
                      loading={loadingReceiptImg}
                    >
                      <Icon type="eye"/>Preview Receipt Image
                    </Button>
                    {transferImgs && transferImgs.map(transferImg => {
                      return (
                        <Button
                          icon="eye"
                          onClick={(e) => this.handlePreview(transferImg)}
                          disabled={_.isEmpty(transferImg)}
                          loading={loadingTransferImgs}
                        >
                          Preview Transfer Image
                        </Button>
                      )
                    })}
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="redo"
                        onClick={this.rotate}
                      />
                      <img
                        alt="example"
                        style={{ width: '100%', transform: `rotate(${rotation}deg)` }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(ShowRemittance);
