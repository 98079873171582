import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Col, Row, Spin, Table
} from 'antd';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

const columns = [
  {
    title: 'Id',
    dataIndex: '_id',
    key: '_id',
    render: _id =>
      <span>{_id}</span>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: name =>
      <span>{name}</span>
  },
];

class ChurchGroups extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    response: [],
    loading: false,
  };

  componentDidMount() {
    this.callApi()
    .then(res => this.setState({ response: res.church_groups, loading: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.callApi()
      .then(res => this.setState({ response: res.church_groups, loading: false }))
      .catch(err => console.log(err));
    }
  }

  callApi = async () => {
    this.setState({ loading: true });
    const location = this.props.location.pathname.split('/')[1];
    const response = await amsAPI.getUrl(`/ams/${location}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  render() {
    const { response, loading } = this.state;
    let modResult = [];
    if (response && response.length > 0) {
      let i = 0;
      response.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
            {(response && response.length === 0) ?
              <div>
                <h3>{`Sorry, but there's are no registered church groups.`}</h3>
              </div>
            :
              <div>
                <h3>{`Here's are the list of church groups`}</h3>
                <Table pagination={false} columns={columns} dataSource={modResult} />
              </div>
            }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(ChurchGroups);
