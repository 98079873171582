import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button, Form, Select, Row, Col, PageHeader, Spin,
  message, Input, InputNumber,
} from 'antd';
import ReactGA from 'react-ga';

import querystring from 'querystring';
import moment from 'moment';

import amsAPI from '../../../apis/amsAPI';
import kapiAPI from '../../../apis/kapiAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
    lg: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class EditKAPIRemittance extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userMemberId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      members: [],
      record: {},
      submitting: false,
      loadingRecord: true,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true });
    this.getRecord()
      .then(res => {
        this.setState({ record: res.data, loadingRecord: false });
      })
  }

  getRecord = async () => {
    const { _id } = this.props.match.params;
    const response = await kapiAPI.getUrl(`/kapi/remittances/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'update remittance'
    });

    e.preventDefault();
    const { _id } = this.props.match.params;
    this.setState({ submitting: true });
    const { record } = this.state;

    kapiAPI.fetchUrl(`/kapi/remittances/${_id}/admin`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        ...record,
        userMemberId: this.props.userMemberId,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Remittance successfully submitted.');
          this.setState({ submitting: false });
          this.props.history.push(`/kapi/remittances`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting remittance.');
    });
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSearchMember = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  render() {
    const { record, members, loadingRecord } = this.state;
    const { member, month, dues, cares, updatedBy, updatedAt } = record;
    const disableSubmit = !member || !month;
    const loading =  loadingRecord;


    const MONTHS = () => {
      const months = []
      const dateStart = moment().add(-6, 'month')
      const dateEnd = moment()
      while (dateEnd.diff(dateStart, 'months') >= 0) {
        months.push(dateStart.format('YYYY-MM'))
        dateStart.add(1, 'month');
      }
      return months
    }

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h2>Edit KAPI Remittance Form</h2>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Member">
                    <Select
                      showSearch
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearchMember}
                      onChange={(value) => this.updateRecord({member: value})}
                      notFoundContent={null}
                      defaultValue={member ? member.name : null}
                      disabled={true}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label={"Month"}>
                  <Select
                    placeholder={"Please select appropriate month"}
                    dropdownMatchSelectWidth={false}
                    optionFilterProp="value"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onChange={(value) => this.updateRecord({ month: value })}
                    notFoundContent={null}
                    defaultValue={month}
                  >
                    {MONTHS().map(item => {
                      return (
                        <Option key={item} value={item}>{item}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label={"KAPI Dues"}>
                  <InputNumber
                    min={1}
                    onChange={(value) => this.updateRecord({ dues: value })}
                    defaultValue={dues}
                  />
                </Form.Item>
                <Form.Item label={"KAPI Cares"}>
                  <InputNumber
                    min={1}
                    onChange={(value) => this.updateRecord({ cares: value })}
                    defaultValue={cares}
                  />
                </Form.Item>
                  <Form.Item label={"Last Updated By"}>
                    <Input
                      defaultValue={updatedBy ? updatedBy.name : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Last Updated At"}>
                    <Input
                      defaultValue={updatedAt ? moment(updatedAt).format("YYYY/MM/DD hh:mm:ss") : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(EditKAPIRemittance);
