import { Button, Col, Icon, Row, Spin, Table } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';
import React, { Component } from 'react';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Report.css';

class SabbathCelebrantsByMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      celebrants: [],
      gatheringTypes: [],
      ministries: [],
      loadingCelebrants: false,
    };

    this.columns = () => {
      return [
        {
          title: 'No',
          dataIndex: 'key',
          key: 'key',
          render: key => <span>{key}</span>
        },
        {
          title: 'Member',
          key: 'member.name',
          render: (text, member) => <span>{member.name}</span>,
        },
        {
          title: 'Locale',
          key: 'member.localeChurchId',
          render: (text, member) => <span>{(member.localeChurchId) ? member.localeChurchId.name : ""}</span>,
        },
        {
          title: 'Baptism Date',
          key: 'member.baptismDate',
          render: (text, member) => {
            const baptismDate = moment(new Date (member.baptismDate));
            return (
              <span>
                {member.baptismDate ?
                  `${moment(member.baptismDate).format("MMM.DD, YYYY")} (${baptismDate.fromNow(true)})`
                  :
                  null
                }
              </span>
            )
        },
        },
      ];
    }

  }

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
    this.getCelebrants()
      .then(res => {
        let modResult = res.data.sort((a, b) => {
          if (!a.localeChurchId || !b.localeChurchId) return -1;
          return a.localeChurchId.name.localeCompare(b.localeChurchId.name);
        });
        this.setState({
          celebrants: modResult,
          loadingCelebrants: false
        });
      })
      .catch(err => console.log(err));

  }

  getCelebrants = async () => {
    this.setState({ loadingCelebrants: true })
    const query = qs.parse(this.props.location.search);
    const response = await amsAPI.getUrl(`/ams/members/celebrants?${qs.stringify(query)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getCelebrants()
      .then(res => {
        let modResult = res.data.sort((a, b) => {
          if (!a.localeChurchId || !b.localeChurchId) return -1;
          return a.localeChurchId.name.localeCompare(b.localeChurchId.name);
        });
        this.setState({
          celebrants: modResult,
          loadingCelebrants: false
        });
      })
      .catch(err => console.log(err));
  }

  downloadTxtFile = async () => {
    const { celebrants } = this.state;
    const query = qs.parse(this.props.location.search);
    const selectedMonthInt = parseInt(query.selectedMonth);

    let modResult = celebrants.sort((a, b) => {
      return a.localeChurchId.name.localeCompare(b.localeChurchId.name);
    });
    const monthFilter = constants.months.filter(month => selectedMonthInt === month.value)[0];
    const sabbathAnniversaryMonth = monthFilter.label.toUpperCase();

    let outputTxt = "";
    outputTxt = outputTxt + `SABBATH CELEBRANTS FOR THE MONTH OF ${sabbathAnniversaryMonth}\r\n`;

    let localeChurch = "";
    let counter = 1;
    modResult.forEach(item => {
      if (localeChurch !== item.localeChurchId.name) {
        counter = 1;
        outputTxt = outputTxt + "\r\n";
        localeChurch = item.localeChurchId.name;
        outputTxt = outputTxt + item.localeChurchId.name.toUpperCase() + "\r\n";
      }

      const baptismDate = moment(new Date (item.baptismDate));
      let member = `  ${counter}. ${item.name} - ${baptismDate.format("MMM.DD, YYYY")} (${baptismDate.fromNow(true)})`;

      outputTxt = outputTxt + member + "\r\n";
      counter++;
    });

    const element = document.createElement("a");
    const file = new Blob([outputTxt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Montly Celebrants_${moment().format('YYYYMMDD_hhmmss')}.txt`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    const { celebrants, loadingCelebrants, loadingMinistries } = this.state;
    const loading = loadingCelebrants || loadingMinistries;
    const hasResult = celebrants.length > 0;

    const query = qs.parse(this.props.location.search);
    const selectedMonthInt = parseInt(query.selectedMonth);
    const sabbathAnniversaryMonth = constants.months.filter(month => month.value === selectedMonthInt)[0].label;

    let columns = this.columns();

    let modResult = [];
    if (hasResult) {
      let i = 1;
      celebrants.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={16}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>{`Monthly Sabbath Celebrants for ${sabbathAnniversaryMonth}`}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={16} style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                onClick={this.downloadTxtFile}
                disabled={!hasResult}
              > Generate Text</Button>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={16} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={16}>
              {(modResult && modResult.length === 0) ?
                <h2>{`No celebrants available for selected month.`}</h2>
              :
                <div>
                  <h3>{`Here are celebrants for the ${sabbathAnniversaryMonth}:`}</h3>
                  <Table pagination={false} columns={columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default SabbathCelebrantsByMonth;
