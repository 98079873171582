import {
  Button, Col, Form, Icon, Input, message, Row, Select, Spin, Table, Tag
} from 'antd';
import PropTypes from "prop-types";
import { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

import { stringify } from 'query-string';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

const { Option } = Select;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const query = { name: value};
    amsAPI.getUrl(`/ams/locale_churches/simple?${stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class MembersList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    membersFilter: PropTypes.object.isRequired,
    setMembersFilter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      churchId: '',
      localeChurches: [],
      ministries: [],
      members: [],
      localeChurchNames: [],
      roles: [],
      loadingMembers: false,
    };
  }

  createTableColumns = async (rolesMap) => {
    const { members } = this.state;
    let churchLocaleNames = [];
    members.forEach(item => {
      if (item.localeChurchId) {
        churchLocaleNames.push(item.localeChurchId.name);
      }
    });

    this.columns = [
      {
        title: 'No',
        dataIndex: 'rowKey',
        key: 'roKey._id',
        render: rowKey =>
          <NavLink
            style={{ padding: 10 }}
            to={`/members/${rowKey._id}/edit`}
          >
            <Icon type={"edit"}/>
          </NavLink>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: name => <span>{name}</span>,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Church ID',
        dataIndex: 'churchId',
        key: 'churchId',
        render: churchId => <span>{churchId}</span>
      },
      {
        title: 'e-mail',
        dataIndex: 'email',
        key: 'email',
        render: email => <span>{email}</span>
      },
      {
        title: 'Locale Church',
        dataIndex: 'localeChurchId',
        key: 'localeChurchId',
        render: localeChurchId => <span>{localeChurchId ? localeChurchId.name : ""}</span>,
      },
      {
        title: 'Roles',
        key: 'roles',
        render: (text, record) => (
          <span>
            {
              record.roles.map(item => {
                return <div key={item}><Tag color="blue">{rolesMap[item]}</Tag></div>
              })
            }
          </span>
        ),
      },
      {
        title: 'Ministries',
        key: 'ministries',
        render: (text, record) => (
          <span>
            {
              record.ministries.map(item => {
                return <div key={item._id}><Tag color="blue">{[item.name]}</Tag></div>
              })
            }
          </span>
        ),
      },
      {
        title: 'Ministry (old)',
        key: 'ministryId',
        dataIndex: 'ministryId',
        render: ministryId => <span>{ministryId ? ministryId.name : ""}</span>,
      },
      {
        title: 'Group',
        dataIndex: 'groupName',
        key: 'groupName',
        render: groupName => <span>{groupName}</span>,
      },
      {
        title: 'Is KAPI Associate',
        dataIndex: 'isKapiAssociate',
        key: 'isKapiAssociate',
        render: isKapiAssociate => <div><Tag color={isKapiAssociate ? "green": "blue"}>{isKapiAssociate ? "Y" : "N"}</Tag></div>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink
              to={`/members/${record._id}/edit`}
            >
              <Button block type="link">
                <Icon type={"edit"}/>
              </Button>
            </NavLink>
            <NavLink
              to={`/members/${record._id}/history`}
            >
              <Button block type="link">
                <Icon type={"audit"}/>
              </Button>
            </NavLink>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingMembers: true, loadingLocaleChurches: true });

    const { name, localeChurchId, email, churchId, roles } = this.props.membersFilter;
    const query = { name, localeChurchId, email, churchId, roles };
    this.findMembers(query)
      .then(res => this.setState({ members: res.members, loadingMembers: false }))
      .catch(err => console.log(err));

    this.getItems('roles')
      .then(res => {
        this.setState({ roles: res.data })
      })
    this.getItems('locale_churches')
      .then(res => {
        this.setState({ localeChurches: res.data, loadingLocaleChurches: false })
      })
    this.getItems('ministries')
      .then(res => {
        this.setState({ ministries: res.data })
      })
  }

  getItems = async (location, query) => {
    const response = await amsAPI.getUrl(`/ams/${location}?${stringify(query)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (memberId) => {
    await amsAPI.fetchUrl(`/ams/members/${memberId}`, {
      method: 'DELETE',
    });
    message.success("Member successfully removed.")
    this.props.history.push('/members');
  };

  handleLocaleSelect = async (value) => {
    this.props.setMembersFilter({ localeChurchId: value });
  };

  handleLocaleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.props.setMembersFilter({ localeChurches: data })
      });
    } else {
      this.props.setMembersFilter({ localeChurches: [] });
    }
  };

  findMembers = async (query) => {
    Object.keys(query).forEach(k => (!query[k] && query[k] !== undefined) && delete query[k]);
    this.setState({ loadingMembers: true });
    const response = await amsAPI.getUrl(`/ams/query/members?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleFilter = async () => {
    const { name, localeChurchId, email, churchId, roles, ministryId } = this.props.membersFilter;
    const query = { name, localeChurchId, email, churchId, roles, ministryId };
    this.findMembers(query)
      .then(res => this.setState({ members: res.members, loadingMembers: false }))
      .catch(err => console.log(err));
  };

  render() {
    const roles = [];
    this.state.roles.forEach(item => {
      roles.push([item.code, item.name]);
    })
    const rolesMap = {};
    this.state.roles.forEach(item => {
      rolesMap[item.code] = item.name;
    })
    this.createTableColumns(rolesMap);
    const { members, ministries, loadingMembers, localeChurches, loadingLocaleChurches } = this.state;
    const loading = loadingMembers || loadingLocaleChurches;

    let modResult = [];
    let i = 0;
    members.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h4>Please input your search conditions:</h4>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form layout="inline">
                <Form.Item label="Name:">
                  <Input
                    placeholder="Search name"
                    value={this.props.membersFilter.name}
                    onChange={e => this.props.setMembersFilter({ name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label="Email:">
                  <Input
                    placeholder="Search email"
                    value={this.state.email}
                    onChange={e => this.props.setMembersFilter({ email: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label="Church Id:">
                  <Input
                    placeholder="Search church id"
                    value={this.state.churchId}
                    onChange={e => this.props.setMembersFilter({ churchId: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label="Locale:">
                  <Select
                    showSearch
                    placeholder="Search a locale"
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    allowClear={true}
                    showArrow={false}
                    onChange={this.handleLocaleSelect}
                    onSearch={this.handleLocaleSearch}
                    notFoundContent={null}
                    style={{ width: 240 }}
                  >
                    {localeChurches && localeChurches.map(item => {
                      return <Option key={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Ministry:">
                  <Select
                    showSearch
                    placeholder="Search a ministry"
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={false}
                    allowClear={true}
                    onChange={value => this.props.setMembersFilter({ ministryId: value })}
                    notFoundContent={null}
                    style={{ width: 240 }}
                  >
                    {ministries && ministries.map(item => {
                      return <Option key={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Roles">
                  <Select
                    placeholder="Please select roles"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.props.setMembersFilter({ roles: value })}
                    allowClear={true}
                    style={{ width: 240 }}
                  >
                    {roles.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                  <Button
                    type="primary"
                    style={{marginLeft: '10px'}}
                    onClick={this.handleFilter}
                  > Filter</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
              {(members.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no registered members.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the list of members:`}</h3>
                  <Table
                    columns={this.columns}
                    dataSource={modResult}
                    pagination= {{
                      showSizeChanger: true,
                      defaultPageSize: 25,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                      pageSizeOptions: ['25', '50', '100', '500', '1000']
                    }}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(MembersList);
