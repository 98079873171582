import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button, Col, Form, Select, Row, DatePicker,
} from 'antd';
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;
const { WeekPicker } = DatePicker;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const query = { name: value};
    amsAPI.getUrl(`/ams/locale_churches/simple?${qs.stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

class ReportsHomeGroupWeeklyReport extends Component {
  state = {
    isProceedDisabled: true,
    weekNum: '',
    selectedGatheringNames: [],
    gatheringTypes: [],
    ministries: [],
    localeChurches: [],
    loadingLocaleChurches: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
    const interval = setInterval(() => {
      console.log('This will be called every 2 seconds');
    }, 2000);

    clearInterval(interval);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { userMemberId} = this.props;
    const { weekNum} = this.state;

    const formData = new FormData();
    formData.append('weekNum', weekNum);
    formData.append('userMemberId', userMemberId);

    await amsAPI.fetchMulter(`/ams/group_reports/generate`, {
      method: 'POST',
      body: formData,
      headers: {
        'Cache-Control': 'no-cache'
      },
    });
  }

  getRequireInfoFromAPI = async () => {
    this.setState({ loadingLocaleChurches: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
    this.callApi('/ams/locale_churches/simple')
      .then(res => {
        this.setState({
          localeChurches: res.data,
          loadingLocaleChurches: false
        })
      })

  }

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleLocaleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ localeChurches: data })
      });
    } else {
      this.setState({ localeChurches: [] });
    }
  };

  render() {
    const {
      weekNum, gatheringTypes, selectedGatheringNames, localeChurches,
      isProceedDisabled,
    } = this.state;
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })

    let query = {
      gathering_names: selectedGatheringNames,
      weekNum: weekNum,
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Weekly Group Report</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h4>Please choose the options to generate a group's weekly summary report.</h4>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item
                  label="Gathering"
                  validateStatus={ !selectedGatheringNames ? "error" : ""}
                  help={ !selectedGatheringNames ? "Please select a gathering" : ""}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={(value) => this.setState({ selectedGatheringNames: value })}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Please select a locale">
                  <Select
                    showSearch
                    placeholder="Search a locale"
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    allowClear={true}
                    showArrow={false}
                    onChange={value => this.setState({ localeChurch: value })}
                    onSearch={this.handleLocaleSearch}
                    notFoundContent={null}
                    style={{ width: 240 }}
                  >
                    {localeChurches && localeChurches.map(item => {
                      return <Option key={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Week Number"
                  validateStatus={ !this.state.weekNum ? "error" : ""}
                  help={ !this.state.weekNum ? "Please select week number" : ""}
                >
                  <WeekPicker
                    showToday
                    format="YYYY-ww"
                    placeholder="Week Number"
                    onChange={(date, dateString) => this.setState({ weekNum: dateString })}
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">Generate</Button>
                  <NavLink to={`/reports/weekly_group_report?${qs.stringify(query)}`}>
                    <Button
                      type="primary"
                      disabled={isProceedDisabled}
                      style={{ marginLeft: 8 }}
                    >
                      Proceed
                    </Button>
                  </NavLink>
                </Form.Item>

              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ReportsHomeGroupWeeklyReport;
