import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import { Button, Col, Row, Spin, Table, Popconfirm, message } from 'antd';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

class LocaleChurchMembers extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      localeChurchInfo: {},
      loadingMembers: false,
    };

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => 
          <NavLink
            style={{ padding: 10 }}
            to={`/members/${record._id}/edit`}
          >{record.name}</NavLink>,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Church Id',
        dataIndex: 'churchId',
        key: 'churchId',
        render: churchId => 
          <span>{churchId}</span>,
        sorter: (a, b) => a.churchId.localeCompare(b.churchId),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromApi();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromApi();
    }
  }

  getRequiredInfoFromApi = async () => {
    this.getLocaleChurchInfo()
      .then(res => this.setState({ localeChurchInfo: res.locale }))
      .catch(err => console.log(err));

    this.getMembers()
      .then(res => this.setState({ members: res.members, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  getLocaleChurchInfo = async () => {
    const { localeId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/locale_churches/${localeId}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async () => {
    this.setState({ loadingMembers: true });
    const { localeId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/locale_churches/${localeId}/members`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (memberId) => {
    await amsAPI.fetchUrl(`/ams/members/${memberId}`, {
      method: 'DELETE',
    });
    message.success("Member successfully removed.")
    this.props.history.push('/members');
  };


  render() {

    const { members, localeChurchInfo, loadingMembers } = this.state;
    let modResult = [];
    let i = 0;
    members.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });

    if (loadingMembers) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
            {(members.length === 0) ?
              <div>
                <h3>{`Sorry, but there are no registered members.`}</h3>
              </div>
            :
              <div>
                <h3>{`Here are the list of members of ${localeChurchInfo.name}:`}</h3>
                <Table columns={this.columns} dataSource={modResult} />
              </div>
            }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(LocaleChurchMembers);
