import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Input, Button, Form, Row, Col, PageHeader, Spin, Checkbox
} from 'antd';

import systemAPI from '../../apis/systemAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditSystemUser extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    user: {},
    loadingUser: false,
    loadingMinistries: false,
    loadingLocaleChurches: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingUser: true });
    this.getUser()
      .then(res => {
        this.setState({ user: res.user, loadingUser: false });
      })
      .catch(err => console.log(err));
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { user } = this.state;
    const { _id } = this.props.match.params;
    await systemAPI.fetchUrl(`/sys/users/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...user}),
    });
    this.props.history.push('/system/users');
  }

  getUser = async () => {
    const { _id } = this.props.match.params;
    const response = await systemAPI.getUrl(`/sys/users/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateInfo = async (updatedInfo) => {
    const currentInfo = this.state.user;
    this.setState({
      user: { ...currentInfo, ...updatedInfo }
    });
  };

  render() {
    const { user, loadingUser } = this.state;
    const loading = loadingUser;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name:"
                  >
                    <Input
                      defaultValue={user.name}
                      onChange={(e) => this.updateInfo({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email:"
                  >
                    <Input
                      defaultValue={user.email}
                      onChange={e => this.updateInfo({ email: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Admin:"
                  >
                    <Checkbox
                      checked={user.isAdmin}
                      onChange={e => this.updateInfo({ isAdmin: e.target.checked })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Update</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(EditSystemUser);
