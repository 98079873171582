import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, Spin, message, DatePicker, Upload, Icon, Input
} from 'antd';
import ReactGA from 'react-ga';
import RemitMethod from './RemitMethod';

import querystring from 'querystring';
import moment from 'moment';

import amsAPI from '../../../apis/amsAPI';
import financeAPI from '../../../apis/financeAPI';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;
const { TextArea } = Input;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class NewRemittance extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      receiptFileList: [],
      remitters: [],
      recipients: [],
      bankAccounts: [],
      record: {},
      submitting: false,
      loadingRecord: true,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true, loadingBankAccounts: true });
    this.getBankAccounts()
      .then(res => {
        this.setState({ bankAccounts: res.data, loadingBankAccounts: false });
      })
      .catch(err => console.log(err));
  }

  getBankAccounts = async () => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit remittance'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const {
      remitter, remitMethod,
      recipient, bankAccount,
      remittanceDate, receiptDate, notes
    } = this.state.record;

    const { fileList, receiptFileList } = this.state;
    const formData = new FormData();

    formData.append('remitter', remitter);
    formData.append('remitMethod', remitMethod);
    formData.append('remittanceDate', remittanceDate);
    formData.append('notes', notes);
    if (fileList[0]) formData.append('remitImg', fileList[0]);

    if (remitMethod === "mail") {
      formData.append('recipient', recipient);
    } else {
      formData.append(
        'bankAccount',
        typeof bankAccount === "object" ? bankAccount._id : bankAccount
      );
    }
    if (receiptDate) formData.append('receiptDate', receiptDate);
    if (receiptFileList[0]) formData.append('receiptImg', receiptFileList[0]);

    financeAPI.fetchMulter(`/fin/remittances`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData,
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Remittance successfully updated.');
          this.setState({ submitting: false });
          this.props.history.push(`/remittances`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting remittance.');
    });
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSearchRemitter = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ remitters: data })
      });
    } else {
      this.setState({ remitters: [] });
    }
  };

  handleSearchRecipient = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ recipients: data })
      });
    } else {
      this.setState({ recipients: [] });
    }
  };

  render() {
    const {
      record, recipients, remitters, bankAccounts,
      loadingRecord, loadingBankAccounts,
      fileList, receiptFileList
    } = this.state;
    const disableSubmit = !record.remitter
      || (!record.recipient && !record.bankAccount)
      || !record.remittanceDate;
    const loading =  loadingRecord || loadingBankAccounts;

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const remitProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const receiptProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.receiptFileList.indexOf(file);
          const newFileList = state.receiptFileList.slice();
          newFileList.splice(index, 1);
          return {
            receiptFileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          receiptFileList: [...state.receiptFileList, file],
        }));
        return false;
      },
      fileList: receiptFileList,
    };

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form>
                <Form.Item label={"Please select the remitter"}>
                  <Select
                      showSearch
                      placeholder={"Input member name or id"}
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearchRemitter}
                      onChange={(value) => this.updateRecord({remitter: value})}
                      notFoundContent={null}
                    >
                      {remitters.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
                <RemitMethod
                  handleSearchRecipient={this.handleSearchRecipient}
                  updateRecord={this.updateRecord}
                  data={record}
                  recipients={recipients}
                  bankAccounts={bankAccounts}
                />
                <Form.Item label={"Remittance Date"}>
                  <DatePicker
                    onChange={(value) => this.updateRecord({ remittanceDate: value })}
                  />
                </Form.Item>
                <Form.Item label={"Remittance Image"}>
                  <Upload {...remitProps}>
                    <Button>
                      <Icon type="upload" /> Select File
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label={"Receipt Date"}>
                  <DatePicker
                    onChange={(value) => this.updateRecord({ receiptDate: value })}
                    defaultValue={record.receiptDate ? moment(record.receiptDate) : null}
                  />
                </Form.Item>
                <Form.Item label={"Receipt Image"}>
                  <Upload {...receiptProps}>
                    <Button>
                      <Icon type="upload" /> Select File
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Breakdown/Notes:">
                  <TextArea
                    autosize={{ minRows: 7 }}
                    onChange={(e) => this.props.updateRecord({ notes: e.target.value })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button block type="primary"
                loading={this.state.submitting}
                onClick={this.handleSubmit}
                disabled={disableSubmit}
              >
                {"Submit"}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default NewRemittance;
