import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Input, Button, Form, Row, Col, PageHeader, Checkbox
} from 'antd';

import amsAPI from '../../apis/amsAPI';
import systemAPI from '../../apis/systemAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class NewSystemUser extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    member: {},
    localeChurches: [],
    loadingLocaleChurches: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingLocaleChurches: true });
    this.getLocaleChurches()
      .then(res => {
        this.setState({ localeChurches: res.locale_churches, loadingLocaleChurches: false })
      })
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { member } = this.state;
    await systemAPI.fetchUrl(`/sys/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...member}),
    });
    this.props.history.push('/system/users');
  }

  getLocaleChurches = async () => {
    const response = await amsAPI.getUrl('/ams/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleLocaleSelect = async (labelKeyValue) => {
    this.updateMember({ localeChurchId: labelKeyValue.key })
  };

  handleRoleSelect = async (value) => {
    console.log('roles', value)
    this.updateMember({ roles: value })
  };

  updateMember = async (updatedInfo) => {
    const currenMemberInfo = this.state.member;
    this.setState({
      member: { ...currenMemberInfo, ...updatedInfo }
    });
  };

  render() {
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name:"
                  >
                    <Input
                      onChange={e => this.updateMember({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email:"
                  >
                    <Input
                      onChange={e => this.updateMember({ email: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                  >
                    <Input
                      type="password"
                      onChange={e => this.updateMember({ password: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Admin:"
                  >
                    <Checkbox
                      onChange={e => this.updateMember({ isAdmin: e.target.checked })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Register</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(NewSystemUser);
