import {
  Button, Col, Collapse, Icon, message, Popconfirm, Row, Spin, Table
} from 'antd';
import moment from "moment";
import PropTypes from "prop-types";
import { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Report.css';

const { Panel } = Collapse;

const columns = [
  {
    title: 'WeekNum',
    key: 'weekNum',
    render: (text, record) => (
      <span>{record.weekNum}</span>
    )
  },
  {
    title: 'Service',
    key: 'service',
    render: (text, record) => (
      <span>{record.serviceCd}</span>
    )
  },
  {
    title: 'Service Date / Time',
    key: 'serviceDate',
    render: (text, record) => (
      <span>{moment(record.serviceDateTime).format('YYYY/MM/DD h:mmA')}</span>
    )
  },
  {
    title: 'Date / Time-In',
    key: 'timeIn',
    render: (text, record) => (
      <span>{record.timeIn ? moment(record.timeIn).format('YYYY/MM/DD h:mmA') : ''}</span>
    )
  },
  {
    title: 'Status',
    key: 'status',
    render: (text, record) => (
      <span>{record.status}</span>
    )
  },
  {
    title: 'Remarks',
    key: 'remarks',
    render: (text, record) => (
      <span>{record.remarks}</span>
    )
  },
];

class IndividualAttendanceReports extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      gatheringTypes: [],
      loading: false,
    };
  }

  createTableColumns = async (gatheringTypesMap) => {
    this.columns = [
      {
        title: 'No',
        dataIndex: 'rowKey',
        key: 'rowNum',
        render: (rowKey) => (
         <span>{rowKey.rowNum}</span>
        )
      },
      {
        title: 'Name',
        key: 'name',
        render: (text, record) => (
          <span>{record.member.name}</span>
        )
      },
      {
        title: 'Attendances',
        key: 'attendances',
        render: (text, record) => (
          <Collapse>
            <Panel header={`Individual Attendance Report for ${record.member.name}`} key="1">
              <Table dataSource={record.attendances} columns={columns} pagination={false} />
            </Panel>
          </Collapse>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <div style={{display: 'flex'}}>
            <NavLink
              to={"/#"}
              onClick={e => e.preventDefault()}
            >
              <Button
                block
                type="link"
                onClick={(e) => {
                  const { _id } = record;
                  amsAPI.fetchUrl(`/ams/individual_attendance_reports/${_id}/pdf`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Cache-Control': 'no-cache',
                    },
                    body: JSON.stringify({
                      _id,
                      filename: _id,
                      content: `Testing Content ${_id}`
                    }),
                  }).then(async res => {
                    if (res.status === 200) {
                      const element = document.createElement("a");
                      const blob = await res.blob();
                      const file = new Blob(
                        [blob],
                        {type: 'application/pdf'}
                      );
                      element.href = URL.createObjectURL(file);

                      // Save the Data
                      const fileName = `Individual Attendance Report - V2_${moment().format('YYYYMMDD_hhmmss')}.pdf`;
                      element.download = fileName;
                      document.body.appendChild(element); // Required for this to work in FireFox
                      element.click();
                    } else {
                      const error = new Error(res.error);
                      throw error;
                    }
                  })
                  .catch(err => {
                    console.error(err);
                    message.error('Error submitting.');
                  });
                }}
              >
                <Icon type="file-pdf" />
              </Button>
            </NavLink>
            <NavLink
              to={"/#"}
              onClick={e => e.preventDefault()}
            >
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record._id)}
              >
                <Button type="link" icon="delete" />
              </Popconfirm>
            </NavLink>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loadingTypes: false }))
      .catch(err => console.log(err));
    this.getItems()
      .then(res => {
        this.setState({ items: res.data, loading: false })
      })
  }

  getItems = async () => {
    const response = await amsAPI.getUrl(`/ams/individual_attendance_reports`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (_id) => {
    await amsAPI.fetchUrl(`/ams/individual_attendance_reports/${_id}`, {
      method: 'DELETE',
    });
    message.success("Individual attendance report successfully deleted.")
    this.props.history.push('/reports/individual_attendance_reports');
  };

  render() {
    const { items, gatheringTypes, loading } = this.state;
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    });
    this.createTableColumns(gatheringTypesMap);

    let modResult = [];
    let i = 0;
    items.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(items.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no church divisions registered.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the church divisions registered:`}</h3>
                  <Table columns={this.columns} dataSource={modResult} pagination={false} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(IndividualAttendanceReports);
