import { Button, Col, DatePicker, Form, Input, message, PageHeader, Row, Select, Spin, Switch } from 'antd';
import _ from 'lodash';
import PropTypes from "prop-types";
import { Component } from 'react';
import { withRouter } from "react-router";

import { stringify } from 'query-string';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import amsAPI from '../../apis/amsAPI';
import financeAPI from '../../apis/financeAPI';
import './CreateForm.css';

const Option = Select.Option;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const query = { name: value};
    amsAPI.getUrl(`/ams/locale_churches/simple?${stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class NewMember extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    member: {},
    localeChurches: [],
    bankAccounts: [],
    ministries: [],
    memberMinistries: [],
    assignedLocaleChurches: [],
    monitoredBankAccounts: [],
    roles: [],
    loadingLocaleChurches: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingLocaleChurches: true });
    this.callApi('/ams/roles')
    .then(res => {
      this.setState({ roles: res.data })
    })
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.ministries, loadingMinistries: false })
      })
      .catch(err => console.log(err));
    this.callApi('/ams/locale_churches/simple')
      .then(res => {
        this.setState({
          localeChurches: res.data,
          assignedLocaleChurches: res.data,
          loadingLocaleChurches: false
        })
      })

    if (this.props.services.fin.isAvailable) {
      this.callFinanceApi("/fin/bank_accounts")
        .then(res => {
          this.setState({ bankAccounts: res.data });
        })
        .catch(err => console.log(err));
    }

  }

  handleSubmit = async e => {
    e.preventDefault();
    const { member } = this.state;
    await amsAPI.fetchUrl(`/ams/locale_churches/${member.localeChurchId}/members`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...member}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        this.setState({ submitting: false });
        if (!response.error_id) {
          message.success('Member successfully added.');
          this.props.history.push('/members');
        } else if (response.error_id === 'ERR001') {
          message.error('Wrong pass key.');
        }
      } else if (res.status === 405) {
        const response = await res.json();
        this.setState({ submitting: false });
        if (!_.isEmpty(response.errors.churchId)) {
          message.error(`Duplicate church id. ${response.errors.churchId.message}`);
        } else {
          message.error(response.message);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error adding member.');
    });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  callFinanceApi = async (url) => {
    const response = await financeAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  handleLocaleSelect = async (value) => {
    this.updateMember({ localeChurchId: value })
  };

  handleLocaleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ localeChurches: data })
      });
    } else {
      this.setState({ localeChurches: [] });
    }
  };

  handleAssignedLocaleSelect = async (value) => {
    this.updateMember({ assignedLocales: value })
  };

  handleAssignedLocaleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ assignedLocaleChurches: data })
      });
    } else {
      this.setState({ assignedLocaleChurches: [] });
    }
  };

  handleRoleSelect = async (value) => {
    this.updateMember({ roles: value })
  };

  handleMinsitrySelect = async (labelKeyValue) => {
    if (!_.isEmpty(labelKeyValue)) {
      this.updateMember({ ministryId: { _id: labelKeyValue.key }, ministries: labelKeyValue.map(i => i.key) })
    } else {
      this.updateMember({ ministryId: null, ministries: [] })
    }
  };

  handleMinsitriesSelect = async (value) => {
    this.updateMember({ ministries: value })
  };

  updateMember = async (updatedInfo) => {
    const currenMemberInfo = this.state.member;
    this.setState({
      member: { ...currenMemberInfo, ...updatedInfo }
    });
  };

  render() {
    const nationalities = constants.nationalities;
    const {
      member, localeChurches, loadingLocaleChurches, assignedLocaleChurches,
      bankAccounts, ministries, roles,
    } = this.state;
    const disableRegister = !member.localeChurchId || !member.name || !member.churchId;
    const loading = loadingLocaleChurches;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Please select a locale">
                    <Select
                      showSearch
                      placeholder="Search a locale"
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      allowClear={true}
                      showArrow={false}
                      onChange={this.handleLocaleSelect}
                      onSearch={this.handleLocaleSearch}
                      notFoundContent={null}
                    >
                      {localeChurches && localeChurches.map(item => {
                        return <Option key={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name"
                  >
                    <Input
                      onChange={(e) => this.updateMember({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Visiting Brethren"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => this.updateMember({ isVisiting: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Monitored"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => this.updateMember({ isMonitored: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is KAPI Associate"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => this.updateMember({ isKapiAssociate: checked })}
                    />
                  </Form.Item>
                  <Form.Item label="Nationality">
                    <Select
                      showSearch
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => this.updateMember({ nationality: value })}
                      allowClear={true}
                    >
                      {nationalities.map(item =>
                        <Option key={item} value={item}>{item}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Date of Birth"
                  >
                    <DatePicker
                      placeholder="Date of Birth"
                      format="YYYY-MM-DD"
                      onChange={(value) => this.updateMember({ birthDate: value.format("YYYY-MM-DD") })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Church Id"
                  >
                    <Input
                      onChange={e => this.updateMember({ churchId: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Baptism Date"
                  >
                    <DatePicker
                      placeholder="Baptism Date"
                      format="YYYY-MM-DD"
                      onChange={(value) => this.updateMember({ baptismDate: value.format("YYYY-MM-DD") })}
                    />
                  </Form.Item>
                  <Form.Item label="Ministry">
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Select a ministry"
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleMinsitriesSelect}
                    >
                      {ministries && ministries.map(ministry => {
                        return <Option key={ministry._id} value={ministry._id}>{ministry.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item
                    label="Is Ministry Trainee"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => this.updateMember({ isUnderProbationary: checked })}
                    />
                  </Form.Item> */}
                  <Form.Item
                    label="Group Name"
                  >
                    <Input
                      onChange={e => this.updateMember({ groupName: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                  >
                    <Input
                      onChange={e => this.updateMember({ email: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Receive Notifications"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={(checked) => this.updateMember({ isNotify: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Enable OTP"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={(checked) => this.updateMember({ isEnableOtp: checked })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="User Link"
                  >
                    <Input
                      onChange={e => this.updateMember({ lineLink: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Admin"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => this.updateMember({ isAdmin: checked })}
                    />
                  </Form.Item>
                  <Form.Item label="Roles">
                    <Select
                      placeholder="Please select roles"
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleRoleSelect}
                    >
                      {roles.map(({ name, code }) =>
                        <Option key={code} value={code}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  {this.props.services.fin.isAvailable &&
                    <Form.Item label={"Bank Account"}>
                      <Select
                        dropdownMatchSelectWidth={false}
                        onChange={value => this.updateMember({ monitoredBankAccounts: value })}
                        mode={"multiple"}
                      >
                        {bankAccounts.map(item => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item label="Assigned Locale/s">
                    <Select
                      showSearch
                      placeholder="Search a locale"
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      allowClear={true}
                      showArrow={false}
                      onChange={this.handleAssignedLocaleSelect}
                      onSearch={this.handleAssignedLocaleSearch}
                      notFoundContent={null}
                      mode={"multiple"}
                    >
                      {assignedLocaleChurches && assignedLocaleChurches.map(item => {
                        return <Option key={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disableRegister}
                    >Register</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(NewMember);
