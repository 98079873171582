import React, { Component } from 'react';
import { Col, Row, Table, Spin, Button, Icon, Checkbox } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Report.css';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: key => <span>{key}</span>
  },
  {
    title: 'Locale',
    dataIndex: 'member',
    key: 'member.localeChurchId',
    render: member => <span>{(member && member.localeChurchId) ? member.localeChurchId.name : ""}</span>,
  },
  {
    title: 'Member',
    dataIndex: 'member',
    key: 'member.name',
    render: member => <span>{member ? member.name : ""}</span>,
  },
  {
    title: 'Church Id',
    dataIndex: 'member',
    key: 'member.churchId',
    render: member => <span>{member ? member.churchId : ""}</span>,
  },
  {
    title: 'Receipt No.',
    dataIndex: 'receiptNumber',
    key: 'receiptNumber',
    render: receiptNumber => <span>{receiptNumber}</span>,
  },
  {
    title: 'Submission',
    dataIndex: 'submissionDateTime',
    key: 'submissionDateTime',
    render: (text, record) => (
      <span>
        {record.submissionDateTime ?
          `${moment(record.submissionDateTime).format("MMM.DD(ddd), h:mmA")}`
          :
          null
        }
      </span>
    ),
  },
  {
    title: 'IP Address',
    dataIndex: 'ipAddress',
    key: 'ipAddress',
    render: (text, record) => (
      <span>{record.ipAddress}</span>
    ),
  },
];

class AttendanceReportByActivity extends Component {
  state = {
    activity: {},
    attendance: [],
    otherLocaleAttendance: [],
    isGenerateIds: false,
    isGenerateIdsAll: false,
    loadingAttendance: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.getAttendance()
      .then(res => {
        let modResult = res.data.sort((a, b) => {
          if (!a.member || !b.member || !a.member.localeChurchId || !b.member.localeChurchId) return -1;
          return a.member.localeChurchId.name.localeCompare(b.member.localeChurchId.name);
        });
        this.setState({
          attendance: modResult,
          otherLocaleAttendance: res.otherLocaleResult,
          loadingAttendance: false
        });
      })
      .catch(err => console.log(err));

    const query = qs.parse(this.props.location.search);
    const { activity_id } = query;
    this.getActivityInfo(activity_id)
      .then(res => this.setState({ activity: res.data }))
      .catch(err => console.log(err));
  }

  getAttendance = async () => {
    this.setState({ loadingAttendance: true })
    const query = qs.parse(this.props.location.search);
    const { activity_id } = query;
    const response = await amsAPI.getUrl(`/ams/locale_attendance/by_activity?activity_id=${activity_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getActivityInfo = async (activity_id) => {
    const response = await amsAPI.getUrl(`/ams/activities/${activity_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async () => {
    this.setState({ loadingMembers: true });
    const response = await amsAPI.getUrl(`/ams/members`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendance()
      .then(res => {
        let modResult = res.data.sort((a, b) => {
          if (!a.member || !b.member || !a.member.localeChurchId || !b.member.localeChurchId) return -1;
          return a.member.localeChurchId.name.localeCompare(b.member.localeChurchId.name);
        });
        this.setState({
          attendance: modResult,
          otherLocaleAttendance: res.otherLocaleResult,
          loadingAttendance: false
        });
      })
      .catch(err => console.log(err));
  }

  downloadTxtFile = async () => {
    const { attendance, otherLocaleAttendance, activity, isGenerateIds, isGenerateIdsAll } = this.state;
    let modResult = attendance.sort((a, b) => {
      return a.member.localeChurchId.name.localeCompare(b.member.localeChurchId.name);
    });
    modResult = modResult.concat(otherLocaleAttendance);

    let outputTxt = "";
    const reportDate = moment(activity.startDateTime).format("h:mmA | dddd | MMMM DD, YYYY");
    outputTxt = outputTxt + "ACTIVITY ATTENDANCE REPORT\r\n";
    outputTxt = outputTxt + constants.activities[activity.name] + "\r\n";
    outputTxt = outputTxt + reportDate + "\r\n";

    if (isGenerateIdsAll) {
      outputTxt = outputTxt + "\r\n";
      modResult.forEach(item => {
        outputTxt = outputTxt + item.member.churchId + "\r\n";
      });

    } else {
      let localeChurch = "";
      let counter = 1;
      modResult.forEach(item => {
        const { member, submissionDateTime } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== member.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = member.localeChurchId.name;
          outputTxt = outputTxt + member.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let memberInfo = "";
        if (isGenerateIds && member.isVisiting) {
          memberInfo = `  ${counter}. ${member.churchId}${member.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        } else {
          memberInfo = `  ${counter}. ${member.name}${member.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        }
        outputTxt = outputTxt + memberInfo + "\r\n";
        counter++;
      });
    }

    const element = document.createElement("a");
    const file = new Blob([outputTxt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Activity_Attendance_Report_${moment().format('YYYYMMDD_hhmmss')}.txt`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    const { attendance, otherLocaleAttendance, loadingAttendance } = this.state;
    const loading = loadingAttendance;
    const hasResult = attendance.length > 0 || otherLocaleAttendance.length > 0;
    let modResult = [];
    if (hasResult) {
      let i = 1;
      attendance.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
      otherLocaleAttendance.forEach(item => {
        modResult.push({ ...item, key: i++});
      })
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Activity Attendance Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                onClick={this.downloadTxtFile}
                disabled={!hasResult}
              > Generate Text</Button>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Checkbox
                  onChange={(e) => this.setState({ isGenerateIdsAll: e.target.checked })}
                  disabled={this.state.isGenerateIds}
                >Generate church IDs only for ALL</Checkbox><br/>
                <Checkbox
                  onChange={(e) => this.setState({ isGenerateIds: e.target.checked })}
                  disabled={this.state.isGenerateIdsAll}
                >Generate church IDs only for visiting brethren</Checkbox>
              </div>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(modResult && modResult.length === 0) ?
                <h2>{`No attendance available for selected activity.`}</h2>
              :
                <div>
                  <h3>{`Here's the attendance for the selected activity:`}</h3>
                  <Table pagination={false} columns={columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default AttendanceReportByActivity;
