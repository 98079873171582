import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, DatePicker, Form, Icon, Select, Row, Spin } from 'antd';
import ReactGA from 'react-ga';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Home.css';

const Option = Select.Option;
const { MonthPicker } = DatePicker;

class MemberReportsHome extends Component {
  state = {
    members: [],
    localeChurches: [],
    selectedMember: '',
    selectedLocale: '',
    period: '',
    loadingLocaleChurches: false,
  };

  componentDidMount() {
    this.getChurchLocalesAndMembers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getChurchLocalesAndMembers();
    }
  }

  getChurchLocalesAndMembers = async () => {
    let storedLocaleId = localStorage.getItem('localeChurchId');
    this.getLocaleChurches()
      .then(res => {
        this.setState({ localeChurches: res.locales, loadingLocaleChurches: false })
        if (storedLocaleId) {
          this.setState({ selectedLocale: storedLocaleId })
        }
      })
      .catch(err => console.log(err));

    if (storedLocaleId) {
      this.getMembers(storedLocaleId)
        .then(res => this.setState({ members: res.members }))
        .catch(err => console.log(err));
    }
  }

  getLocaleChurches = async () => {
    const response = await amsAPI.getUrl('/ams/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async (id) => {
    const response = await amsAPI.getUrl(`/ams/locale_churches/${id}/members`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSelect = async (value) => {
    ReactGA.event({
      category: 'Client Report',
      action: 'locale church select'
    });
    this.setState({ selectedLocale: value });

    this.getMembers(value)
      .then(res => this.setState({ members: res.members }))
      .catch(err => console.log(err));
  };

  onChange = async (date, dateString) => {
    this.setState({ period: dateString})
  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const { localeChurches, members, selectedLocale, selectedMember, period, loadingLocaleChurches } = this.state;
    let selectedMemberId = '';
    if (selectedMember && selectedMember.split(',').length > 1) {
      selectedMemberId = selectedMember.split(',')[2];
    }

    if (loadingLocaleChurches) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h2>Please select the report you would like to generate.</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Locale:">
                  <Select
                      showSearch
                      placeholder="Select your locale church"
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleSelect}
                      value={selectedLocale}
                    >
                      {localeChurches && localeChurches.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Church id:" >
                  <Select
                      showSearch
                      placeholder="Input member name or id"
                      dropdownMatchSelectWidth={false}
                      optionFilterProp="value"
                      onChange={value => this.setState({ selectedMember: value }) }
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={`${item.churchId},${item.name},${item._id}`}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Period">
                  <MonthPicker onChange={this.onChange} placeholder="Select month" />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <NavLink to={`/reports/member_attendance?memberId=${selectedMemberId}&period=${period}`}>
                <Button
                  block
                  type="primary"
                  disabled={ !selectedLocale || !selectedMember || !period }
                >
                  Next<Icon type="right"/>
                </Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default MemberReportsHome;
