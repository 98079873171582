import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Input, PageHeader, message, Icon, Tooltip,
} from 'antd';
import ReactGA from 'react-ga';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import financeAPI from '../../apis/financeAPI';

import 'antd/dist/antd.css';
import './Home.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
  },
};

class GenerateToken extends Component {
  state = {
    record: {},
    loading: false,
    submitting: false,
  };

  componentDidMount() {
    const pathname = this.props.location.pathname.split("/").slice(0,3).join("/");
    const jpRemitUrl = process.env.REACT_APP_JPREMIT_URL.replace(/\/$/, "");
    this.updateRecord({ url: `${jpRemitUrl}/${pathname.substring(1)}/edit` })
  }

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit new bank account'
    });

    e.preventDefault();
    const { record } = this.state;
    this.setState({ submitting: true });
    financeAPI.fetchUrl(`/fin/security_tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Successfully added.');
          this.setState({ submitting: false });
          this.props.history.push(`/remittances`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  render() {
    const { url } = this.state.record;
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>Edit Remittance Link</h3>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="URL:">
                    <Input
                      value={url}
                      disabled={true}
                    />
                    <CopyToClipboard text={url}>
                      <Tooltip title="Copied!" trigger="click">
                        <Button>
                          <Icon type="copy"/>
                        </Button>
                      </Tooltip>
                    </CopyToClipboard>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default GenerateToken;
