import React, { Component } from 'react';
import amsAPI from '../../apis/amsAPI';
import {
  Form, Input, Button, message,
  PageHeader, Row, Col, Select,
} from 'antd';
import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class CreateLocaleChurch extends Component {
  state = {
    record: {},
    countries: [],
    submitting: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi('/ams/countries')
      .then(res => {
        this.setState({ countries: res.data })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { record } = this.state;
    this.setState({ submitting: true });
    amsAPI.fetchUrl('/ams/locale_churches', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Successfully added.');
          this.setState({ submitting: false });
          this.props.history.push(`/locale_churches`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  render() {
    const { record, countries } = this.state;
    const { name } = record;
    const disableSubmit = !name;

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Locale Name:"
                  >
                    <Input
                      onChange={e => this.updateRecord({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Code:"
                  >
                    <Input
                      onChange={e => this.updateRecord({ code: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label="Country:">
                    <Select
                      placeholder="Select a country"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateRecord({ country: value })}
                      disabled={countries.length === 0}
                    >
                      {countries.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Location"
                  >
                    <Input
                      onChange={e => this.updateRecord({ location: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                  >
                    <Input
                      onChange={e => this.updateRecord({ address: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default CreateLocaleChurch;
