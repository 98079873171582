import React, { Component } from 'react';
import { PageHeader, Row, Col, Icon, Card } from 'antd';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class SystemHome extends Component {

  render() {
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/system/users">
                    <Icon type="user-add" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/system/roles">
                    <Icon type="control" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/secret">
                    <Icon type="lock" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default SystemHome;
