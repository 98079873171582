import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import { PageHeader, Row, Col, Icon, Card } from 'antd';

import 'antd/dist/antd.css';
import './Home.css';

class LocaleChurchHome extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { localeId } = this.props.match.params;
    return (
      <PageHeader
        title={`You are inside locae ${localeId}?`}
      >
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to={`/locale_churches/${localeId}/members`}>
                    <Icon type="team" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to={`/locale_churches/${localeId}/stores`}>
                    <Icon type="shop" style={{ fontSize: '4em', color: '#08c' }}/>
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to={`/locale_churches/${localeId}/church_groups`}>
                    <Icon type="cluster" style={{ fontSize: '4em', color: '#08c' }}/>
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default withRouter(LocaleChurchHome);
