import { Button, Col, DatePicker, Form, Icon, Row, Select, Spin, Table } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Report.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

let timeout;
let currentValue;

function fetch(params, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = params.value;

  async function fake() {
    const { value } = params;
    const query = { q: value };
    amsAPI.getUrl(`/ams/members?${qs.stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: key => <span>{key}</span>
  },
  {
    title: 'Receipt No.',
    key: 'receiptNumber',
    render: (text, record) => (
      <span>
        <NavLink
          to={`/gatherings/${record.gatheringId._id}/member_attendance/${record._id}/edit`}
        >
          {record.receiptNumber}
        </NavLink>
      </span>
    ),
  },
  {
    title: 'Platform',
    dataIndex: 'channel',
    key: 'channel',
    render: channel => <span>{channel ? channel.toUpperCase() : ""}</span>,
  },
  {
    title: 'Gathering',
    key: 'gatheringId',
    render: (text, record) => (
      <span>
        {record.gatheringId ?
          <span>
            {constants.gatherings[record.gatheringId.name]}<br/>
            {moment(record.gatheringId.startDateTime).format("MMM.DD(ddd), h:mmA")}
          </span>
          :
          null
        }
      </span>
    ),
  },
];

class IndividualAttendanceReport extends Component {
  state = {
    startDate: '',
    endDate: '',
    preparedBy: '',
    verifiedBy: '',
    approvedBy: '',
    selectedGatheringTypes: [
      'pm', 'spm', 'pmws', 'ws', 'tg', 'spbb1', 'spbb2', 'spbb3', 'ls', 'nyr', 'spbb1d',
      // 'gph1', 'gph2', 'gph3', 'gph4', 'gph5',
      // 'mcl1', 'mcl2', 'mcl3', 'mcl4', 'mcl5',
      // 'mi01', 'mi02', 'mi03', 'mi04', 'mi05',
      // 'mi06', 'mi07', 'mi08', 'mi09', 'mi10',
      // 'mi11', 'mi12', 'mi13', 'mi14',
      // 'sbk1', 'sbk2', 'sbk3', 'sbk4', 'sbk5',
    ],
    members: [],
    attendance: [],
    gatheringTypes: [],
    locale_secretaries: [],
    locale_coordinators: [],
    elders: [],
    loadingAttendance: false,
  };

  componentDidMount() {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.callApi(`/ams/gathering_types`)
        .then(res => this.setState({ gatheringTypes: res.data }))
        .catch(err => console.log(err));
    }
  }

  getAttendance = async () => {
    this.setState({ loadingAttendance: true });
    const { memberId, startDate, endDate, selectedGatheringTypes } = this.state;
    const query = { memberId, startDate, endDate, gatheringTypes: selectedGatheringTypes };
    const response = await amsAPI.getUrl(`/ams/member_attendance?${qs.stringify(query)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendance()
      .then(res => {
        let modResult = res.memberAttendanceList.sort((a, b) => {
          if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
          return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
        });
        this.setState({
          attendance: modResult,
          loadingAttendance: false
        });
      })
      .catch(err => console.log(err));
  }

  handleFilter = async () => {
    this.setState({ loadingAttendance: true });
    this.getAttendance()
      .then(res => {
        let modResult = res.memberAttendanceList.sort((a, b) => {
          if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
          return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
        });
        this.setState({
          attendance: modResult,
          loadingAttendance: false
        });
      })
      .catch(err => console.log(err));
  };

  handleSearch = (value) => {
    if (value) {
      const params = { value };
      fetch(params, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = value => {
    this.setState({ memberId: value.key, memberName: value.label });
    this.callApi(`/ams/members/${value.key}`)
      .then(res => {
        let localeChurch = res.data.localeChurchId;
        let lsec_query = { localeChurchId: localeChurch._id, roles: ['lsc'] };
        this.callApi(`/ams/members/officers?${qs.stringify(lsec_query)}`)
          .then(res => {
            this.setState({ locale_secretaries: res.data });
          })
          .catch(err => console.log(err));

        let lcrd_query = { localeChurchId: localeChurch._id, roles: ['llc'] };
        this.callApi(`/ams/members/officers?${qs.stringify(lcrd_query)}`)
          .then(res => {
            this.setState({ locale_coordinators: res.data });
          })
          .catch(err => console.log(err));

        let eld_query = { assignedLocales: [localeChurch._id] };
        this.callApi(`/ams/members/elders?${qs.stringify(eld_query)}`)
          .then(res => {
            this.setState({ elders: res.data });
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  handleRangeChange = (date, dateString) => {
    this.setState({ startDate: dateString[0], endDate: dateString[1]})
  }

  downloadPdf = async () => {
    const {
      memberId, startDate, endDate,
      preparedBy, verifiedBy, approvedBy,
      selectedGatheringTypes,
    } = this.state;
    const query = {
      memberId, startDate, endDate,
      preparedBy, verifiedBy, approvedBy,
      gatheringTypes: selectedGatheringTypes
    };

    amsAPI.fetchUrl(`/ams/member_attendance/${memberId}/pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(query),
    }).then(async res => {
      if (res.status === 200) {
        const element = document.createElement("a");
        const blob = await res.blob();
        const file = new Blob(
          [blob],
          {type: 'application/pdf'}
        );
        element.href = URL.createObjectURL(file);

        // Save the Data
        const fileName = `Individual Attendance Report_${moment().format('YYYYMMDD_hhmmss')}.pdf`;
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
    });
  }

  downloadTxtFile = async () => {
    const { memberName, attendance } = this.state;
    let modResult = attendance.sort((a, b) => {
      return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
    });

    let outputTxt = "";
    outputTxt = outputTxt + "INDIVIDUAL HOOKUP ATTENDANCE REPORT\r\n";
    outputTxt = outputTxt + "\r\n";
    outputTxt = outputTxt + `Name: ${memberName}\r\n`;

    let counter = 1;
    modResult.forEach(item => {
      if (counter === 1) {
        const localeChurch = item.memberId.localeChurchId.name.toUpperCase();
        outputTxt = outputTxt + `Locale: ${localeChurch}\r\n`;
        outputTxt = outputTxt + "\r\n";
      }

      const reportDate = moment(item.gatheringId.startDateTime).format("h:mmA | dddd | MMMM DD, YYYY");
      let gathering = `  ${counter}. ${item.channel.toUpperCase()} : ${constants.gatherings[item.gatheringId.name]} | ` + reportDate;
      outputTxt = outputTxt + gathering + "\r\n";
      counter++;
    });

    const element = document.createElement("a");
    const file = new Blob([outputTxt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Attendance Report_${moment().format('YYYYMMDD_hhmmss')}.txt`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    const {
      memberId, attendance, members, gatheringTypes, selectedGatheringTypes,
      loadingAttendance, locale_secretaries, locale_coordinators, elders,
      startDate, endDate, preparedBy, verifiedBy, approvedBy,
    } = this.state;
    const loading = loadingAttendance;
    const hasResult = attendance.length > 0;
    const disablePdfBtn = !hasResult || !memberId || !preparedBy || !verifiedBy || !approvedBy;

    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })

    let modResult = [];
    if (hasResult) {
      let i = 1;
      attendance.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Individual Attendance Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
              <Button
                type="primary"
                onClick={this.downloadTxtFile}
                disabled={!hasResult}
              > Generate Text</Button>
              <Button
                type="primary"
                onClick={this.downloadPdf}
                disabled={disablePdfBtn}
                style={{ marginLeft: '10px' }}
              > PDF</Button>
              </span>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Name:">
                  <Select
                    showSearch
                    labelInValue
                    optionFilterProp="label"
                    optionLabelProp="label"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => this.handleSearch(value)}
                    onChange={this.handleChange}
                    notFoundContent={null}
                  >
                    {members.map(item => {
                      return (
                        <Option key={item._id} value={item._id} label={item.name}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Range:">
                  <RangePicker  format={'YYYY/MM/DD'} onChange={this.handleRangeChange} />
                </Form.Item>
                <Form.Item label="Gathering/s:">
                  <Select
                    allowClear
                    placeholder="Select the gathering type"
                    onChange={value => this.setState({ selectedGatheringTypes: value })}
                    mode="multiple"
                    defaultValue={selectedGatheringTypes}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <h3>For PDF Generation Only</h3>
                <Form.Item label="Prepared By:">
                  <Select
                    onChange={value => this.setState({ preparedBy: value })}
                    disabled={!memberId}
                  >
                    {locale_secretaries.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Verified By:">
                  <Select
                    onChange={value => this.setState({ verifiedBy: value })}
                    disabled={!memberId}
                  >
                    {locale_coordinators.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Approved By:">
                  <Select
                    onChange={value => this.setState({ approvedBy: value })}
                    disabled={!memberId}
                  >
                    {elders.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleFilter}
                    disabled={!startDate || !endDate || !memberId}
                  >Search</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(modResult && modResult.length === 0) ?
                <h2>{`No attendance available.`}</h2>
              :
                <div>
                  <h3>{`Here's the attendance for ${this.state.memberName}:`}</h3>
                  <Table pagination={false} columns={columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default IndividualAttendanceReport;
