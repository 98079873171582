import { Button, Col, Icon, message, Popconfirm, Row, Spin, Table } from 'antd';
import moment from 'moment';
import PropTypes from "prop-types";
import { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

class GatheringAttendees extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      gathering: {},
      members: [],
      localeChurchNames: [],
      loadingMembers: false,
    };
  }
  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'memberId',
        key: 'memberId',
        sorter: (a, b) => a.memberId.name.localeCompare(b.memberId.name),
        render: (text, record) => (
          <span>
            {(record.memberId && record.memberId.name) ? record.memberId.name : ""}
          </span>
        ),
      },
      {
        title: 'Locale Church',
        dataIndex: 'memberId.localeChurchId',
        key: 'memberId.localeChurchId',
        render: (text, record) => (
          <span>
            {(record.memberId && record.memberId.localeChurchId) ? record.memberId.localeChurchId.name : ""}
          </span>
        ),
      },
      {
        title: 'Submission',
        dataIndex: 'submissionDateTime',
        key: 'submissionDateTime',
        render: (text, record) => (
          <span>
            {record.submissionDateTime ?
              `${moment(record.submissionDateTime).format("MMM.DD(ddd), h:mmA")}`
              :
              null
            }
          </span>
        ),
      },
      {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        render: (text, record) => (
          <span>
            {record.ipAddress ?
              record.ipAddress
              :
              null
            }
          </span>
        ),
      },
      {
        title: 'Receipt #',
        dataIndex: 'receiptNumber',
        key: 'receiptNumber',
        render: (text, record) => (
          <span>{record.receiptNumber}</span>
        ),
      },
      {
        title: 'Guest/s',
        dataIndex: 'guestAttendance',
        key: 'guestAttendance',
        render: (text, record) => (
          <span>
            {record.guestAttendance.map(item => {
              return <div>{item.religion + " : " + item.count}</div>
            })}
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
            <NavLink
              to={`/gatherings/${this.props.match.params.gatheringId}/member_attendance/${record._id}/edit`}
            >
              <Button type="link" icon="edit" />
            </NavLink>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getAttendees()
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));

    const { gatheringId } = this.props.match.params;
    this.getGathering(gatheringId)
      .then(res => this.setState({ gathering: res.gathering }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getAttendees()
        .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
        .catch(err => console.log(err));
    }
  }

  getAttendees = async () => {
    this.setState({ loadingMembers: true });
    const { gatheringId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}/attendees`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendees()
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  handleDelete = async (_id) => {
    await amsAPI.fetchUrl(`/ams/member_attendance/${_id}`, {
      method: 'DELETE',
    });
    message.success("Attendance successfully removed.");
    const { gatheringId } = this.props.match.params;
    this.props.history.push(`/gatherings/${gatheringId}/attendees`);
  };

  render() {
    this.createTableColumns();
    const { members, loadingMembers, gathering } = this.state;
    let modResult = [];
    let i = 0;
    members.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });
    const gatheringStr = gathering?.name?.toUpperCase() + ' ' + moment(gathering.startDateTime).format("MMM.DD, YYYY (ddd) h:mmA");

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>
                Gatherings Attendees Page<br/>
                {gatheringStr}
              </h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          {loadingMembers ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(members.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no approved attendees for this gathering.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the list of attendees for :`}</h3>
                  <Table pagination={false} columns={this.columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(GatheringAttendees);
