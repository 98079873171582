import { Button, Col, DatePicker, Form, Row, Select, Spin, Switch, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import * as qs from 'query-string';
import { Component } from 'react';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Report.css';

const { Option } = Select;
const { RangePicker } = DatePicker;



const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

let timeout;
let currentValue;

function fetch(params, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = params.value;

  async function fake() {
    const { value } = params;
    const query = { q: value };
    amsAPI.getUrl(`/ams/members?${qs.stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const columns = [
  {
    title: 'WeekNum',
    key: 'weekNum',
    render: (text, record) => (
      <span>{record.weekNum}</span>
    )
  },
  {
    title: 'Service',
    key: 'service',
    render: (text, record) => (
      <span>{record.serviceCd}</span>
    )
  },
  {
    title: 'Service Date / Time',
    key: 'serviceDate',
    render: (text, record) => (
      <span>{moment(record.serviceDateTime).format('YYYY/MM/DD h:mmA')}</span>
    )
  },
  {
    title: 'Date / Time-In',
    key: 'timeIn',
    render: (text, record) => (
      <span>{record.timeIn ? moment(record.timeIn).format('YYYY/MM/DD h:mmA') : ''}</span>
    )
  },
  {
    title: 'Status',
    key: 'status',
    render: (text, record) => (
      <span>{record.status}</span>
    )
  },
  {
    title: 'Remarks',
    key: 'remarks',
    render: (text, record) => (
      <span>{record.remarks}</span>
    )
  },
];

class IndividualAttendanceReportV2 extends Component {
  state = {
    startDate: '',
    endDate: '',
    preparedBy: '',
    verifiedBy: '',
    approvedBy: '',
    selectedGatheringTypes: [
      'pm', 'spm', 'pmws', 'ws', 'tg', 'spbb1', 'spbb2', 'spbb3', 'ls', 'nyr', 'spbb1d',
      // 'gph1', 'gph2', 'gph3', 'gph4', 'gph5',
      // 'mcl1', 'mcl2', 'mcl3', 'mcl4', 'mcl5',
      // 'mi01', 'mi02', 'mi03', 'mi04', 'mi05',
      // 'mi06', 'mi07', 'mi08', 'mi09', 'mi10',
      // 'mi11', 'mi12', 'mi13', 'mi14',
      // 'sbk1', 'sbk2', 'sbk3', 'sbk4', 'sbk5',
    ],
    members: [],
    attendances: [],
    gatheringTypes: [],
    locale_secretaries: [],
    locale_coordinators: [],
    elders: [],
    loadingAttendance: false,
    enableDownload: false,
    sortByGatheringAsc: true,
  };

  componentDidMount() {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.callApi(`/ams/gathering_types`)
        .then(res => this.setState({ gatheringTypes: res.data }))
        .catch(err => console.log(err));
    }
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSearch = (value) => {
    if (value) {
      const params = { value };
      fetch(params, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = value => {
    this.setState({ memberId: value.key, memberName: value.label });
    this.callApi(`/ams/members/${value.key}`)
      .then(res => {
        let localeChurch = res.data.localeChurchId;
        let lsec_query = { localeChurchId: localeChurch._id, roles: ['lsc'] };
        this.callApi(`/ams/members/officers?${qs.stringify(lsec_query)}`)
          .then(res => {
            this.setState({ locale_secretaries: res.data });
          })
          .catch(err => console.log(err));

        let lcrd_query = { localeChurchId: localeChurch._id, roles: ['llc'] };
        this.callApi(`/ams/members/officers?${qs.stringify(lcrd_query)}`)
          .then(res => {
            this.setState({ locale_coordinators: res.data });
          })
          .catch(err => console.log(err));

        let eld_query = { assignedLocales: [localeChurch._id] };
        this.callApi(`/ams/members/elders?${qs.stringify(eld_query)}`)
          .then(res => {
            this.setState({ elders: res.data });
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  handleRangeChange = (date, dateString) => {
    this.setState({ startDate: dateString[0], endDate: dateString[1]})
  }

  generateReport = async () => {
    const {
      memberId, startDate, endDate,
      preparedBy, verifiedBy, approvedBy,
      selectedGatheringTypes, sortByGatheringAsc,
    } = this.state;
    const payload = {
      memberId, startDate, endDate,
      preparedBy, verifiedBy, approvedBy, sortByGatheringAsc,
      gatheringTypes: selectedGatheringTypes
    };

    amsAPI.fetchUrl(`/ams/individual_attendance_reports/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }).then(async res => {
      if (res.status === 200) {
        const body = await res.json();
        this.setState({ report: body.data, enableDownload: true })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
    });
  }

  downloadPdf = async () => {
    const {
      report, memberId, startDate, endDate,
      preparedBy, verifiedBy, approvedBy, sortByGatheringAsc,
    } = this.state;
    const query = {
      memberId, startDate, endDate,
      preparedBy, verifiedBy, approvedBy, sortByGatheringAsc,
    };

    amsAPI.fetchUrl(`/ams/individual_attendance_reports/${report._id}/pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(query),
    }).then(async res => {
      if (res.status === 200) {
        const element = document.createElement("a");
        const blob = await res.blob();
        const file = new Blob(
          [blob],
          {type: 'application/pdf'}
        );
        element.href = URL.createObjectURL(file);

        // Save the Data
        const fileName = `Individual Attendance Report - V2_${moment().format('YYYYMMDD_hhmmss')}.pdf`;
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
    });
  }

  render() {
    const {
      report, memberId, members, gatheringTypes, selectedGatheringTypes,
      loadingAttendance, locale_secretaries, locale_coordinators, elders,
      startDate, endDate, enableDownload, sortByGatheringAsc,
    } = this.state;
    const loading = loadingAttendance;

    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Individual Attendance Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Name:"> 
                  <Select
                    showSearch
                    labelInValue
                    optionFilterProp="label"
                    optionLabelProp="label"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => this.handleSearch(value)}
                    onChange={this.handleChange}
                    notFoundContent={null}
                  >
                    {members.map(item => {
                      return (
                        <Option key={item._id} value={item._id} label={item.name}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Range:">
                  <RangePicker  format={'YYYY-MM-DD'} onChange={this.handleRangeChange} />
                </Form.Item>
                <Form.Item label="Gathering/s:">
                  <Select
                    allowClear
                    placeholder="Select the gathering type"
                    onChange={value => this.setState({ selectedGatheringTypes: value })}
                    mode="multiple"
                    defaultValue={selectedGatheringTypes}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Sort By Gathering:" colon>
                  <Switch
                    checkedChildren="Oldest to Newest (Ascending)"
                    unCheckedChildren="Newest to Oldest (Descending)"
                    checked={sortByGatheringAsc}
                    onChange={(checked) => {
                      this.setState({ sortByGatheringAsc: checked })
                    }}
                    style={{ marginLeft: 10 }}
                  />
                </Form.Item>
                <h3>For PDF Generation Only</h3>
                <Form.Item label="Prepared By:">
                  <Select
                    onChange={value => this.setState({ preparedBy: value })}
                    disabled={!memberId || enableDownload}
                  >
                    {locale_secretaries.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Verified By:">
                  <Select
                    onChange={value => this.setState({ verifiedBy: value })}
                    disabled={!memberId || enableDownload}
                  >
                    {locale_coordinators.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Approved By:">
                  <Select
                    onChange={value => this.setState({ approvedBy: value })}
                    disabled={!memberId || enableDownload}
                  >
                    {elders.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button
                      type="primary"
                      onClick={this.generateReport}
                      disabled={!startDate || !endDate || !memberId || enableDownload}
                      style={{ margin: '5px' }}
                    >Generate</Button>
                    <Button
                      type="primary"
                      onClick={this.downloadPdf}
                      disabled={!enableDownload}
                      style={{ margin: '5px' }}
                    >Download</Button>
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(_.isEmpty(report?.attendances)) ?
                <h2>{`No attendance available.`}</h2>
              :
                <div>
                  <Table pagination={false} columns={columns} dataSource={report?.attendances} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default IndividualAttendanceReportV2;
